import { faCube } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import _isEmpty from 'lodash/isEmpty';
import _truncate from 'lodash/truncate';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import ResourcesModalWithScrollPagination from 'rapidfab/components/records/run/ResourcesModalWithScrollPagination';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { rcTooltipInnerStyleList } from 'rapidfab/constants/styles';
import * as Selectors from 'rapidfab/selectors';
import {
  getAllLineItemsForRun,
  getRunActualsForRun,
  getUUIDResource,
} from 'rapidfab/selectors';
import { getRunActualsDurationForRun } from 'rapidfab/utils/timeUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React, { useEffect, useMemo, useState } from 'react';
import BatchRunSummary from 'rapidfab/components/inventory/BatchRunSummary';
import { useDispatch, useSelector } from 'react-redux';

const BatchRunSummaryContainer = ({ batchUri }) => {
  const lineItemsInitialLimit = 10;

  const [run, setRun] = useState(null);

  const runFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN].get.fetching);
  const piecesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PIECES_BY_RUN].list.fetching);
  const lineItemsResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN].list.fetching
    || state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching,
  );
  const printerFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER].get.fetching);
  const printerTypeFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].get.fetching);
  const runActuals = useSelector(state => getRunActualsForRun(state, run));
  const runActualsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.RUN_ACTUALS].list.fetching);
  const lineItemsRelatedResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.LINE_ITEM].list.fetching
    || state.ui.nautilus[API_RESOURCES.ORDER].list.fetching
  || state.ui.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN].list.fetching);
  const startTime =
    runActuals?.start_in_progress_time &&
    dayjs(runActuals.start_in_progress_time).format('MM/DD/YYYY, hh:mm A');
  const endTime =
    runActuals?.end_in_progress_time &&
    dayjs(runActuals.end_in_progress_time).format('MM/DD/YYYY, hh:mm A');

  const printer = useSelector(state =>
    (run?.printer ? getUUIDResource(state, extractUuid(run.printer)) : null));
  const printerType = useSelector(state =>
    (printer?.printer_type ? getUUIDResource(state, extractUuid(printer.printer_type)) : null));

  const lineItemsForRun = useSelector(state => (run ? getAllLineItemsForRun(state, run) : null));
  const ordersByUri = useSelector(Selectors.getOrdersByUri);
  const lineItemsGroupedByOrderUri = useSelector(Selectors.getLineItemsGroupedByOrderUri);

  const [pieceCount, setPieceCount] = useState(0);
  const [lineItemsCount, setLineItemsCount] = useState(0);
  const [runLineItemsFetchState, setRunLineItemsFetchState] = useState({ offset: 0, count: 1 });
  const [lineItemsModalVisible, setLineItemsModalVisible] = useState(false);

  const duration = useMemo(() => {
    if (_isEmpty(runActuals)) return null;
    return getRunActualsDurationForRun(runActuals.start_in_progress_time, runActuals.end_in_progress_time);
  }, [runActuals]);

  const dispatch = useDispatch();

  const onInitialize = async () => {
    if (!batchUri) return;

    const runActualsAdditiveDataResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS_ADDITIVE]
      .list({ material_batch: batchUri }));

    const { resources: runActualsAdditiveData } = runActualsAdditiveDataResponse?.json;

    // Return if there are no run actuals additive data or more than one
    // (as only one card should be displayed)
    if (_isEmpty(runActualsAdditiveData) || runActualsAdditiveData?.length > 1) return;

    const runActuals = await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN_ACTUALS]
      .get(runActualsAdditiveData[0].run_actual));
    const runActualsData = runActuals?.json ?? {};

    const run = runActualsData?.run;

    if (!run) return;

    const runResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.RUN].get(run));

    const { uri, printer, printer_type } = runResponse?.json ?? {};

    if (!uri) {
      return;
    }
    setRun(runResponse.json);

    await Promise.all([
      printer
        ? dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(printer))
        : Promise.resolve(),
      printer_type
        ? dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(printer_type))
        : Promise.resolve(),
    ]);

    const pieceCountResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.PIECES_BY_RUN].list(
      { run: uri },
      { limit: 1 },
      {},
      {},
      true,
    ));

    const { count } = pieceCountResponse.json?.meta ?? {};

    if (count) {
      setPieceCount(count);
    }

    await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN].clear(''));
    const lineItemsByRunResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN]
      .list({ run: uri }, { limit: lineItemsInitialLimit, offset: 0 }, {}, {}, true));

    if (lineItemsByRunResponse && !_isEmpty(lineItemsByRunResponse.json?.resources)) {
      const lineItemUris = lineItemsByRunResponse.json.resources.map(lineItemByRun => lineItemByRun.line_item);
      const { count } = lineItemsByRunResponse.json?.meta ?? {};

      setRunLineItemsFetchState(previous => (
        { offset: previous.offset + lineItemsInitialLimit,
          count: lineItemsByRunResponse?.json.meta?.count || 0 }
      ));
      setLineItemsCount(count);

      if (!_isEmpty(lineItemUris)) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM].clear(''));
        const lineItemResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM]
          .list({ uri: lineItemUris }, {}, {}, {}, true));

        const lineItems = lineItemResponse.json?.resources;

        if (!_isEmpty(lineItems)) {
          const lineItemOrderUris = [...new Set(lineItems
            .map(lineItem => lineItem.order).filter(Boolean))];

          if (!_isEmpty(lineItemOrderUris)) {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].clear(''));
            await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
              { uri: lineItemOrderUris }, {}, {}, {}, true));
          }
        }
      }
    }
  };

  const fetchMoreLineItems = async runUri => {
    const lineItemsByRunResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEMS_BY_RUN]
      .list(
        { run: runUri },
        { limit: lineItemsInitialLimit, offset: runLineItemsFetchState.offset }, {}, {}, true));

    if (lineItemsByRunResponse && !_isEmpty(lineItemsByRunResponse.json?.resources)) {
      const { count } = lineItemsByRunResponse.json?.meta ?? {};
      const lineItemUris = lineItemsByRunResponse.json.resources.map(lineItemByRun => lineItemByRun.line_item);

      setRunLineItemsFetchState(previous => (
        { offset: previous.offset + lineItemsInitialLimit, count: count || 0 }
      ));

      if (!_isEmpty(lineItemUris)) {
        const lineItemResponse = await dispatch(Actions.Api.nautilus[API_RESOURCES.LINE_ITEM]
          .list({ uri: lineItemUris }, {}, {}, {}, true));
        const lineItems = lineItemResponse.json?.resources;

        if (!_isEmpty(lineItems)) {
          const lineItemOrderUris = [...new Set(lineItems
            .map(lineItem => lineItem.order).filter(Boolean))];

          if (!_isEmpty(lineItemOrderUris)) {
            await dispatch(Actions.Api.nautilus[API_RESOURCES.ORDER].list(
              { uri: lineItemOrderUris }, {}, {}, {}, true));
          }
        }
      }
    }
  };

  const toggleLineItemsModal = () => setLineItemsModalVisible(previous => !previous);
  const closeLineItemsModal = () => setLineItemsModalVisible(false);

  useEffect(() => {
    onInitialize();
  }, []);

  if (!batchUri || _isEmpty(runActuals)) return null;

  const data = {
    run,
    printer,
    printerType,
    duration,
    pieceCount,
    lineItemsCount,
    startTime,
    endTime,
    lineItemsForRun,
    lineItemsInitialLimit,
  };

  const fetchingStates = {
    runFetching,
    printerFetching,
    printerTypeFetching,
    piecesFetching,
    lineItemsResourcesFetching,
    runActualsFetching,
  };

  const actions = {
    toggleLineItemsModal,
  };

  return (
    <>

      <BatchRunSummary data={data} fetchingStates={fetchingStates} actions={actions} />
      {lineItemsModalVisible && (
        <ResourcesModalWithScrollPagination
          show={lineItemsModalVisible}
          fetchResourceUri={run?.uri}
          onClose={closeLineItemsModal}
          resourcesList={lineItemsForRun}
          resourcesListFetching={lineItemsRelatedResourcesFetching}
          fetchMoreResources={runLineItemsFetchState.offset < runLineItemsFetchState.count
            ? fetchMoreLineItems
            : null}
          title="Line Items"
          content={(
            lineItemsGroupedByOrderUri.map(([orderUri, lineItems]) => {
              const order = ordersByUri[orderUri] ?? { name: 'Order not rendered' };
              return (
                <div>
                  <div className="ResourceCardListOrderList">
                    {order.name.length > 30 ? (
                      <RCTooltip
                        placement="top"
                        id="orderTooltip"
                        destroyTooltipOnHide
                        overlayInnerStyle={rcTooltipInnerStyleList}
                        mouseLeaveDelay={0.4}
                        overlay={(
                          <div>
                            {order.name}
                          </div>
                        )}
                      >
                        <div>{_truncate(order.name, { length: 30 })}</div>
                      </RCTooltip>
                    ) : <div>{order.name}</div>}
                  </div>

                  {lineItems.map(({ order, name }) => (
                    <div key={name} className="d-flex align-items-center spacer-bottom mb5">
                      <FontAwesomeIcon icon={faCube} className="spacer-right" />
                      <a
                        href={getRouteURI(ROUTES.ORDER_EDIT, { uuid: extractUuid(order) })}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="font-size-16 spacer-right"
                      >
                        <TruncatedTitle
                          title={name}
                          maxTextLength={40}
                          inline
                        />
                      </a>
                    </div>
                  ))}
                </div>
              );
            })
          )}
        />
      )}
    </>
  );
};

BatchRunSummaryContainer.propTypes = {
  batchUri: PropTypes.string.isRequired,
};

export default BatchRunSummaryContainer;
