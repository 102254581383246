import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Sentry from '@sentry/react';
import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import _map from 'lodash/map';
import _sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import DebugModeDataPanel from 'rapidfab/components/DebugMode/DebugModeDataPanel';
import Feature from 'rapidfab/components/Feature';
import PriorityLabel from 'rapidfab/components/records/run/PriorityLabel';
import Config from 'rapidfab/config';
import { API_RESOURCES, COLORS, FEATURES, ROUTES } from 'rapidfab/constants';
import { FormattedMessage } from 'rapidfab/i18n';
import { MODELER_STATUS_TOOLTIP_MAP, RUN_STATUS_MAP } from 'rapidfab/mappings';
import 'rapidfab/styles/componentStyles/gantt-chart.scss';
import { downtimeResourceType } from 'rapidfab/types';
import formatGanttDateHeaderLabel from 'rapidfab/utils/formatGanttDateHeaderLabel';
import { getNonIntegratedMachines } from 'rapidfab/utils/jeniUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import Tooltip from 'rc-tooltip';
import 'rc-tooltip/assets/bootstrap_white.css';
import React, { useState } from 'react';
import { Tooltip as BootstrapTooltip, OverlayTrigger } from 'react-bootstrap';
import Timeline, {
  DateHeader,
  TimelineHeaders,
  TimelineMarkers,
  TodayMarker,
} from 'react-calendar-timeline';
import 'react-calendar-timeline/lib/Timeline.css';
import { useSelector } from 'react-redux';

dayjs.extend(weekday);

const MAX_ZOOM = 365.24 * 86400 * 1000; // 1 year in ms
const MIN_ZOOM = 15 * 60 * 1000; // 15 min in ms

// since react-calendar-timeline does not have any built-in way to add titles /
// sections, the machines are manually ordered and title items are inserted
// between groups of printers and post processors as follows
const orderMachinesWithTitles = (machines, { isGeneralMFGLanguageEnabled, isJeniClusterFeatureEnabled }) => {
  const sortedMachines = _sortBy(machines, 'name');

  const { printers, postProcessors } =
    getNonIntegratedMachines(sortedMachines, { isJeniClusterFeatureEnabled });

  const integratedPrinters = _filter(sortedMachines, ({ printer_type }) => printer_type
    && printer_type?.integrated);
  const integratedPostProcessors = _filter(sortedMachines, ({ post_processor_type }) => post_processor_type
    && post_processor_type?.integrated);

  const convertedPrinterName = isGeneralMFGLanguageEnabled ? 'Primary Production' : 'Printers';

  const orderedData = [
    { uri: 'printerTitle', name: convertedPrinterName, isGroupHeader: true }, // uri key used since machine uri is used for item key
    ...printers,
    { uri: 'postProcessorsTitle', name: 'Post Processors', isGroupHeader: true }, // uri key used since machine uri is used for item key
    ...postProcessors,
  ];

  if (isJeniClusterFeatureEnabled) {
    orderedData.push({ uri: 'jeniTitle', name: 'JENI', isGroupHeader: true },
      ...integratedPrinters,
      ...integratedPostProcessors);
  }

  return orderedData;
};

const groupRenderer = (
  { group },
  toggleWorkScheduleDisplayRow,
  isDebugModeEnabled,
  runsFetching,
) => {
  const { title, uuid, status, isGroupHeader } = group;
  const isPrinter = group.printer_type;
  const isPostProcessor = group.post_processor_type;
  const isWorkScheduleDisplay = group.is_work_schedule_display_group;
  const href = isPrinter
    ? getRouteURI(ROUTES.PRINTER_EDIT, { uuid })
    : getRouteURI(ROUTES.POST_PROCESSOR_EDIT, { uuid });
  if (isGroupHeader) return <b>{title}</b>;
  const statusData = MODELER_STATUS_TOOLTIP_MAP[status];
  const dotClass = statusData ? statusData.status : 'unknown';
  const dotTitle = statusData ? statusData.message : 'Unknown';
  return (
    <div className="spacer-left">
      {isPrinter && (
        <OverlayTrigger
          placement="right"
          overlay={(
            <BootstrapTooltip id={uuid}>
              {dotTitle}
            </BootstrapTooltip>
          )}
        >
          <span className={`dot ${dotClass} spacer-right`} title={dotTitle} />
        </OverlayTrigger>
      )}
      {isPostProcessor && <FontAwesomeIcon icon={faWrench} className="spacer-right" />}
      <a href={!isWorkScheduleDisplay && href}>{title}</a>
      {isDebugModeEnabled && !runsFetching && group.work_schedule && (
        <Feature featureName={FEATURES.WORK_SCHEDULE}>
          <OverlayTrigger
            placement="right"
            overlay={(
              <BootstrapTooltip id={uuid}>
                Click to see labor availability schedule.
              </BootstrapTooltip>
            )}
          >
            <FontAwesomeIcon
              role="button"
              className="spacer-left"
              icon={faClock}
              onClick={() => toggleWorkScheduleDisplayRow(group.id)}
            />
          </OverlayTrigger>
        </Feature>
      )}
    </div>
  );
};

const itemRenderer = ({
  item,
  itemContext,
  getItemProps,
}, runsByUri, buildsByRunUri, downtimesByUri, customFieldsByUri) => {
  const itemProps = getItemProps(item.itemProps);
  const isBuildAttached = buildsByRunUri[item.id];
  const run = runsByUri[item.id];
  const downtime = downtimesByUri[item.id];
  const isWorkScheduleDisplay = item.is_work_schedule_display_item;
  const { backgroundColor, borderColor } = item;
  const itemPropsToPass = {
    ...itemProps,
    style: {
      ...itemProps.style,
      backgroundColor,
      border: `1.5px solid ${borderColor}`,
      boxShadow: '1px 1px 3px #222',
      fontWeight: isWorkScheduleDisplay ? 'bold' : null,
    },
  };

  const runBodyLine = (
    <div {...itemPropsToPass}>
      <div
        className="rct-item-content"
        data-id={run?.id}
        style={{ maxHeight: `${itemContext.dimensions.height}` }}
      >
        {itemContext.title} {isBuildAttached && '*'}
      </div>
    </div>
  );

  if (item.id.includes('buffer')) {
    return (
      <Tooltip
        placement="top"
        destroyTooltipOnHide
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        id={item.id}
        overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
        overlay={(
          <div className="QueuesStyleOverlay">
            {item.tooltip}
          </div>
        )}
      >
        {runBodyLine}
      </Tooltip>
    );
  }

  return (
    run ? (
      <Tooltip
        placement="bottom"
        destroyTooltipOnHide
        mouseEnterDelay={0}
        mouseLeaveDelay={0.1}
        id={run.id}
        overlayInnerStyle={{ padding: '10px', wordBreak: 'break-word' }}
        overlay={(
          <div className="QueuesStyleOverlay">
            <>
              <div>
                <b>Name: </b>{run.name}
              </div>
              <div>
                <b>Status: </b>
                <FormattedMessage
                  {...RUN_STATUS_MAP[run.status]}
                />
              </div>
              <div>
                <b>Pieces in Run: </b>{run.prints ? run.prints.length : 'Unavailable'}
              </div>
              <div>
                <b>Priority: </b>
                <PriorityLabel labelOnly value={run.priority} />
              </div>
              <div>
                <b>Locked: </b>{run.pieces_locked ? 'Yes' : 'No'}
              </div>
              <div>
                <b>Linked to Build: </b>{isBuildAttached ? 'Yes' : 'No'}
              </div>
              {
                !isEmpty(run.custom_field_values) && (
                  run.custom_field_values.map(({ custom_field, value }) => (
                    <>
                      <div>
                        <b>{customFieldsByUri[custom_field].field_name}:{' '}</b>{value}
                      </div>
                    </>
                  ))
                )
              }
              <div className="QueuesStyleQrContainer">
                <a className="QueuesStyleQrLink" href={`${Config.HOST.QR}/traveler/run/${run.uuid}`}>
                  <BureauBarcodeIcon className="spacer-right" />
                </a>
              </div>
            </>
            {downtime && (
              <>
                <div>
                  <b>Workstation Downtime</b>
                </div>
                <div>
                  <b>Description: </b>{downtime.description || 'None'}
                </div>
              </>
            )}
          </div>
        )}
      >
        {runBodyLine}
      </Tooltip>
    ) : runBodyLine
  );
};

const onItemSelect = (uri, runsByUri, downtimesByUri) => {
  let currentUri = uri;

  if (uri.includes('buffer')) {
    currentUri = uri.split('buffer')[0].toString();
  }
  const run = runsByUri[currentUri];

  if (run) {
    // If the item is a Run, navigate to the run page
    window.location = getRouteURI(ROUTES.RUN_EDIT, { uuid: extractUuid(currentUri) });
    return null;
  }

  if (uri.includes('WORK_SCHEDULE_')) {
    // We clicked on the "Work Schedule" Labor Item
    return null;
  }

  const downtime = downtimesByUri[currentUri];
  if (!downtime) {
    Sentry.captureMessage(`[Gantt chart] No downtime found with ${currentUri} resource`);
    return null;
  }
  if (downtime.printer) {
    // If the item is a downtime of a printer, navigate to the Printer page
    window.location = getRouteURI(
      ROUTES.PRINTER_EDIT,
      { uuid: extractUuid(downtime.printer) },
    );
    return null;
  }

  if (downtime.post_processor) {
    // If the item is a downtime of a post processor, navigate to the Post Processor page
    window.location = getRouteURI(
      ROUTES.POST_PROCESSOR_EDIT,
      { uuid: extractUuid(downtime.post_processor) },
    );
    return null;
  }

  Sentry.captureMessage(`[Gantt chart] Can't find proper redirect route for ${currentUri} resource`);
  return null;
};

const GanttChart = ({
  events,
  machines,
  buildsByRunUri,
  runsByUri,
  downtimesByUri,
  workSchedulesByPostProcessorType,
  workSchedulesByPrinterType,
  isGeneralMFGLanguageEnabled,
  isDebugModeEnabled,
  defaultStartTime,
  defaultEndTime,
  handleGanttScroll,
  scheduledRuns,
  isJeniClusterFeatureEnabled,
  customFieldsByUri,
}) => {
  const DAYJS_WEEKDAY_MAPPING = {
    monday: 1,
    tuesday: 2,
    wednesday: 3,
    thursday: 4,
    friday: 5,
    saturday: 6,
    sunday: 7,
  };

  const WORK_SCHEDULE_TYPES = {
    CALENDAR: 'calendar',
    NON_STOP: 'non_stop',
  };

  const items = _map(events, ({ start, end, resourceId, title, id, backgroundColor, borderColor, tooltip }) => ({
    title,
    id,
    tooltip,
    start_time: start,
    end_time: end,
    group: resourceId,
    backgroundColor,
    borderColor,
  }));

  const groups = _map(orderMachinesWithTitles(machines, { isGeneralMFGLanguageEnabled, isJeniClusterFeatureEnabled }),
    ({ uri, id, name, ...rest }) => ({
      id: uri,
      title: name,
      work_schedule:
      workSchedulesByPostProcessorType[rest.post_processor_type?.uri] ||
      workSchedulesByPrinterType[rest.printer_type?.uri],
      ...rest,
    }));

  const [updatedGroups, setUpdatedGroups] = useState([]);
  const [updatedItems, setUpdatedItems] = useState([]);
  const [currentWorkScheduleDisplayHighlightedRows,
    setCurrentWorkScheduleDisplayHighlightedRows] = useState([]);
  const runsFetching = useSelector(state => state.ui.nautilus[API_RESOURCES.SCHEDULE_RUNS].list.fetching);

  const toggleWorkScheduleDisplayRow = groupId => {
    /* Check debug mode is enabled */
    if (!isDebugModeEnabled) {
      return;
    }

    setCurrentWorkScheduleDisplayHighlightedRows(previous => [...previous, groupId]);
    setCurrentWorkScheduleDisplayHighlightedRows(previous => previous.filter(id => id === groupId));
    const clickedGroup = _find(groups, { id: groupId });

    const updatedGroups = [...groups];
    let updatedItems = [...items];

    if (currentWorkScheduleDisplayHighlightedRows.includes(groupId)) {
      setCurrentWorkScheduleDisplayHighlightedRows(previous => previous.filter(id => id !== groupId));
    } else {
      const daysPrevious = [];

      const printerTypeWorkSchedule = workSchedulesByPrinterType[clickedGroup.printer_type?.uri];
      const postProcessorTypeWorkSchedule = workSchedulesByPostProcessorType[clickedGroup.post_processor_type?.uri];

      const workScheduleDays = clickedGroup.printer_type ?
        Object.entries(printerTypeWorkSchedule.week) :
        Object.entries(postProcessorTypeWorkSchedule.week);

      const workScheduleUTCOffset = clickedGroup.printer_type ?
        printerTypeWorkSchedule.utc_offset :
        postProcessorTypeWorkSchedule.utc_offset;

      const totalUTCOffsetSplit = String(workScheduleUTCOffset).split('.');
      let [totalUTCOffsetHour, totalUTCOffsetMinute] = totalUTCOffsetSplit;
      const remainder = (workScheduleUTCOffset || 0) % 1;
      if (workScheduleUTCOffset < 0) {
        totalUTCOffsetMinute = 60 * remainder * -1;
      } else {
        totalUTCOffsetMinute = 60 * remainder;
      }

      totalUTCOffsetHour = Number(totalUTCOffsetHour);

      /* Item schema for if the work schedule is type: `non_stop` */
      const nonStopUpTimeDisplayItem = {
        id: `WORK_SCHEDULE_${Math.floor(Math.random() * 99999)}`,
        is_work_schedule_display_item: true,
        title: 'Labor Available',
        start_time: dayjs().subtract(1, 'year'),
        end_time: dayjs().add(1, 'year'),
        group: groupId,
        backgroundColor: COLORS.PURPLE,
      };

      const upTimeDisplayItems = _flattenDeep(
        _map(
          workScheduleDays,
          ([day, value]) => {
            daysPrevious.push(day);
            const result = [];
            const { start, finish } = value;

            for (let index = 0; index < 52; index += 7) {
              result.push({
                id: `WORK_SCHEDULE_${Math.floor(Math.random() * 99999)}`,
                is_work_schedule_display_item: true,
                title: 'Labor Available',
                start_time: dayjs().weekday(index + DAYJS_WEEKDAY_MAPPING[day]).startOf('day')
                  .set('hour', start.h - totalUTCOffsetHour)
                  .set('minute', start.m - totalUTCOffsetMinute)
                  .utc(true)
                  .local(),
                end_time: dayjs().weekday(index + DAYJS_WEEKDAY_MAPPING[day]).startOf('day')
                  .set('hour', finish.h - totalUTCOffsetHour)
                  .set('minute', finish.m - totalUTCOffsetMinute)
                  .utc(true)
                  .local(),
                group: groupId,
                backgroundColor: COLORS.PURPLE,
              });
            }
            return result;
          },
        ),
      );

      if (
        printerTypeWorkSchedule?.type === WORK_SCHEDULE_TYPES.NON_STOP ||
        postProcessorTypeWorkSchedule?.type === WORK_SCHEDULE_TYPES.NON_STOP
      ) {
        updatedItems = [nonStopUpTimeDisplayItem, ...updatedItems];
      } else if (
        printerTypeWorkSchedule?.type === WORK_SCHEDULE_TYPES.CALENDAR ||
        postProcessorTypeWorkSchedule?.type === WORK_SCHEDULE_TYPES.CALENDAR
      ) {
        updatedItems = [...upTimeDisplayItems, ...updatedItems];
      }
    }
    setUpdatedGroups(updatedGroups);
    setUpdatedItems(updatedItems);
  };

  const DebugDataValues = {
    name: 'Scheduled Runs',
    current_Starting_Date: dayjs(defaultStartTime).subtract(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    current_Ending_Date: dayjs(defaultEndTime).add(3, 'month').format('YYYY-MM-DD HH:mm:ss'),
    available_scheduled_runs: scheduledRuns,
  };

  return (

    <>
      {isDebugModeEnabled && (
        <DebugModeDataPanel style={{ marginBottom: 10 }} className="spacer-bottom" data={DebugDataValues} />
      )}
      <Timeline
        onTimeChange={(start, end, updateScrollCanvas) => {
          updateScrollCanvas(start, end);
          const startTimeHasReachedEndOfQueryTimelinePast = dayjs(start) < dayjs(defaultStartTime).subtract(3, 'month');
          const startTimeHasReachedEndOfQueryTimelineFuture = dayjs(start) > dayjs(defaultEndTime).add(3, 'month');
          if (startTimeHasReachedEndOfQueryTimelinePast || startTimeHasReachedEndOfQueryTimelineFuture) {
            handleGanttScroll(start, end);
          }
        }}
        groups={updatedGroups.length ? updatedGroups : groups}
        items={updatedItems.length ? updatedItems : items}
        defaultTimeStart={defaultStartTime} // 6 hr before current time
        defaultTimeEnd={defaultEndTime} // 6 hr after current time
        maxZoom={MAX_ZOOM}
        minZoom={MIN_ZOOM}
        canMove={false}
        canResize={false}
        canChangeGroup={false}
        lineHeight={40}
        sidebarWidth={250}
        timeSteps={{
          second: 30,
          minute: 15,
          hour: 1,
          day: 1,
          month: 1,
          year: 1,
        }}
        onItemSelect={uri => onItemSelect(uri, runsByUri, downtimesByUri)}
        itemRenderer={item => itemRenderer(item, runsByUri, buildsByRunUri, downtimesByUri, customFieldsByUri)}
        groupRenderer={group => groupRenderer(
          group,
          groupId => toggleWorkScheduleDisplayRow(groupId),
          isDebugModeEnabled,
          runsFetching)}
        horizontalLineClassNamesForGroup={group =>
          (currentWorkScheduleDisplayHighlightedRows.includes(group.id) ?
            ['work-schedule-horizontal-line'] : [])}
        stackItems
      >
        <TimelineHeaders>
          <DateHeader unit="primaryHeader" />
          <DateHeader
            height={40}
            labelFormat={formatGanttDateHeaderLabel}
          />
        </TimelineHeaders>
        <TimelineMarkers>
          <TodayMarker>
            {({ styles }) =>
              <div style={{ ...styles, backgroundColor: COLORS.PURPLE }} />}
          </TodayMarker>
        </TimelineMarkers>
      </Timeline>
    </>
  );
};

GanttChart.propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      resourceId: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      start: PropTypes.string,
      end: PropTypes.string,
      backgroundColor: PropTypes.string,
      borderColor: PropTypes.string,
    }),
  ).isRequired,
  machines: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  customFieldsByUri: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  buildsByRunUri: PropTypes.shape({}).isRequired,
  runsByUri: PropTypes.objectOf(PropTypes.shape({})).isRequired,
  downtimesByUri: PropTypes.objectOf(downtimeResourceType).isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  workSchedulesByPostProcessorType: PropTypes.shape({}).isRequired,
  workSchedulesByPrinterType: PropTypes.shape({}).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  handleGanttScroll: PropTypes.func.isRequired,
  defaultStartTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  defaultEndTime: PropTypes.shape({
    format: PropTypes.func,
  }).isRequired,
  scheduledRuns: PropTypes.shape([]).isRequired,
  isJeniClusterFeatureEnabled: PropTypes.bool.isRequired,
};

export default GanttChart;
