import { LINE_ITEM_STATUS, MODEL_LIBRARY_TYPES } from 'rapidfab/constants';
import { createSelector } from 'reselect';

import { getPredicate, getStateResources } from 'rapidfab/selectors/helpers/base';
import { getBuilds } from 'rapidfab/selectors/build';
import { getPrints, getPrintingPrintsCreated } from 'rapidfab/selectors/print';
import { getPostProcessors } from 'rapidfab/selectors/postProcessor';
import { getPrinters } from 'rapidfab/selectors/printer';
import { getProcessSteps } from 'rapidfab/selectors/processStep';
import { getLayouts } from 'rapidfab/selectors/layout';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _map from 'lodash/map';
import _uniq from 'lodash/uniq';
import _compact from 'lodash/compact';
import _keyBy from 'lodash/keyBy';
import _sortBy from 'lodash/sortBy';
import _reduce from 'lodash/reduce';
import _omit from 'lodash/omit';
import _isEmpty from 'lodash/isEmpty';
import * as baseStateSelectors from 'rapidfab/selectors/baseStateSelectors';
import { getLineItems } from 'rapidfab/selectors/lineItem';
import { getPiecesWithPrintingPrintsCreated, getPiecesByUri } from 'rapidfab/selectors/piece';
import { getModelsByUri } from 'rapidfab/selectors/model';
import { getMaterialsByUri } from 'rapidfab/selectors/material';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { getOrders } from 'rapidfab/selectors/order';
import { getRunEstimates } from 'rapidfab/selectors/runEstimates';
import { getRunActualsByRunUri } from 'rapidfab/selectors/runActuals';
import { getActiveBuildFiles } from 'rapidfab/selectors/buildFile';
import { getLineItemWorkflowTypeObjectKey } from 'rapidfab/utils/lineItemUtils';

// This selector returns line items from 'line-item-with-available-for-run-prints' endpoint
// It may have extra or missing fields comparing to regular line items.
// One significant difference is that `uri` differs and looks like this
// `https://NAUTILUS-DOMAIN/line-item-with-available-for-run-prints/UUID/`
export const getLineItemsWithAvailableForRunPrints = createSelector(
  [baseStateSelectors.getStateLineItemsWithAvailableForRunPrints, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getLineItemsWithAvailableForRunPrintsByUuid = createSelector(
  [getLineItemsWithAvailableForRunPrints],
  lineItemsWithAvailableForRunPrints => _keyBy(lineItemsWithAvailableForRunPrints, 'uuid'),
);

export const getPostProcessorsForRun = createSelector(
  [getPostProcessors, getPredicate],
  (postProcessors, run) => {
    if (!run) {
      return [];
    }

    return _filter(postProcessors, { post_processor_type: run.post_processor_type });
  },
);

export const getPrintersForRun = createSelector(
  [getPrinters, getPredicate],
  (printers, run) => {
    if (!run) {
      return [];
    }

    return _filter(printers, { printer_type: run.printer_type });
  },
);

// !!!ATTENTION!!! For Run we return all prints, including Remanufactured ones
export const getRunPrints = createSelector(
  [getPredicate, getPrints],
  (run, prints) => {
    if (!run) {
      return [];
    }

    return _filter(prints, { run: run.uri });
  },
);

const getLineItemsForRunPrints = createSelector(
  [getPredicate, getRunPrints, getLineItems],
  (run, prints, lineItems) => {
    if (!run) return [];
    const lineItemUris = _uniq(_map(prints, 'line_item'));
    return _filter(lineItems, ({ uri }) => lineItemUris.includes(uri));
  },
);

export const getRunLayerThickness = createSelector(
  [getPredicate, getLineItemsForRunPrints],
  (run, lineItemsForRun) => {
    if (!lineItemsForRun.length) return null;
    // Skip 'specimen'
    const productLineItems = _filter(lineItemsForRun, { type: MODEL_LIBRARY_TYPES.PRODUCT });
    const layerThicknessValues = _map(productLineItems, 'layer_thickness');
    const unitValues = _map(productLineItems, 'model_unit');
    // determine if layer thickness & model_unit values are equal
    const areLayerThicknessesEqual = _uniq(layerThicknessValues).length === 1;
    const areUnitsEqual = _uniq(unitValues).length === 1;
    // if all layer thicknesses & units are the same, return those values
    if (areLayerThicknessesEqual && areUnitsEqual) {
      const lineItem = productLineItems[0];
      // `additive` or `powder`
      const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
      const { layer_thickness, model_unit } = lineItem[workflowTypeKey];
      return { value: layer_thickness, units: model_unit };
    }
    // if there are multiple line items and the layer thicknesses aren't the
    // same, return null
    return null;
  },
);

export const getSpecificWorkstationUrisForPrints = createSelector(
  [getPredicate, getProcessSteps],
  (prints, processSteps) => {
    if (!prints) {
      return [];
    }

    const printsProcessStepUris = _map(prints, 'process_step');

    const processStepsForPrints = _filter(
      processSteps,
      processStep => printsProcessStepUris.includes(processStep.uri),
    );

    return _compact(_uniq(_map(processStepsForPrints, 'workstation')));
  },
);

// !!!ATTENTION!!! For Run we return all prints, including Remanufactured ones
export const getRunPrintsGridData = createSelector(
  [getPredicate, getOrders, getLineItems, getRunPrints],
  (run, orders, lineItems, runPrints) => {
    if (!run) return [];
    return runPrints
      .map(print => {
        const printOrder = orders.find(order => order.uri === print.order);
        const printLineItem = lineItems.find(lineItem => lineItem.uri === print.line_item);
        return {
          ...print,
          dueDate: printOrder && printOrder.due_date,
          partName: printLineItem && printLineItem.name,
        };
      });
  },
);

export const getOrderedRunPrintsGridData = createSelector(
  [getPredicate, getRunPrintsGridData, getLayouts, getActiveBuildFiles],
  (run, gridData, layouts, buildFiles) => {
    let orderedGridData = gridData;
    if (!run) return orderedGridData;
    const runBuildFile = _find(buildFiles, buildFile => ((buildFile.run === run.uri) && (buildFile.layout !== null)));
    if (!runBuildFile) return orderedGridData;

    const layout = _find(layouts, { uri: runBuildFile.layout });
    if (layout) {
      const orderedPrintUris = _map(layout.positions, 'print').filter(Boolean);
      // some older layouts don't have prints; in this case, return gridData
      const layoutHasPrints = orderedPrintUris.length;
      if (!layoutHasPrints) return orderedGridData;
      orderedGridData = _map(
        orderedPrintUris, printUri => _find(orderedGridData, { uri: printUri }),
      );
      orderedGridData = _sortBy(orderedGridData, 'uuid');
    }

    return orderedGridData;
  },
);

export const getBuildFilesForRun = createSelector(
  [getPredicate, getActiveBuildFiles],
  (run, buildFiles) => {
    if (!run) return null;
    return _filter(buildFiles, file => file.run === run.uri
      || run.uri.includes(file.run));
  },
);

export const getRunDocuments = createSelector(
  [baseStateSelectors.getStateDocuments, getStateResources],
  (uuids, resources) => _filter(uuids.map(uuid => resources[uuid]), ['related_table_name', 'run']),
);

const hydrateLineItemForRunNew = (lineItem, materialsByUri, modelsByUri) => {
  const workflowTypeKey = getLineItemWorkflowTypeObjectKey(lineItem);
  const baseMaterial = materialsByUri[lineItem[workflowTypeKey]?.materials?.base];
  const supportMaterial = materialsByUri[lineItem[workflowTypeKey]?.materials?.support];

  const lineItemModel = modelsByUri[lineItem[workflowTypeKey]?.model];
  if (!baseMaterial || !lineItemModel) {
    return null;
  }

  return { ...lineItem,
    [workflowTypeKey]: {
      ...lineItem[workflowTypeKey],
      model: lineItemModel,
      materials: {
        base: baseMaterial,
        support: supportMaterial,
      },
    } };
};

export const getPiecesForRunNew = createSelector(
  [
    getLineItemsWithAvailableForRunPrintsByUuid,
    getPiecesByUri,
    getMaterialsByUri,
    getPrintingPrintsCreated,
    getModelsByUri,
  ],
  (lineItemsByUuid, piecesByUri, materialsByUri, prints, modelsByUri) =>
  /*
   * Returns all pieces for new run
   * Patch into piece optional (if not loaded yet) custom lineItem object
   *   (with base material, support material and model objects)
   * Patch into piece optional (if not loaded yet) printingPrint object.
   *
   * TODO Iterate is done by printing prints, might be replaced to piece
   *  when new filters for piece are implemented
   *
   * Order for getPrintingPrintsCreated must be preserved
   *
   * {
   *   ...,
   *   lineItem: {
   *     materials: {
   *         base: {},
   *         support: {}, // can be null
   *     },
   *     model: {},
   *   },
   *   printingPrint: {},
   * }
   */

    _map(
      prints,
      print => {
        // line items are loaded from another endpoint (and non-standard selector is used)
        // so we have to compare them by uuid, not uri.
        const lineItem = lineItemsByUuid[extractUuid(print.line_item)];
        const piece = piecesByUri[print.piece];

        if (!piece || !lineItem) {
          // Not loaded yet
          return null;
        }

        const hydratedRecord = hydrateLineItemForRunNew(lineItem, materialsByUri, modelsByUri);
        if (!hydratedRecord) {
          // Material or model is not in the store yet
          return null;
        }

        return { ...piece, lineItem: hydratedRecord };
      },
    ).filter(Boolean),
);

export const getRunPieces = createSelector(
  [getRunPrints, getPiecesByUri],
  (prints, piecesByUri) => _compact(
    _map(prints, ({ piece: pieceUri }) => piecesByUri[pieceUri]),
  ),
);

export const getLineItemsForRunNew = createSelector(
  [
    getLineItemsWithAvailableForRunPrints, getMaterialsByUri,
    getModelsByUri, getPiecesWithPrintingPrintsCreated,
  ],
  (lineItems, materialsByUri, modelsByUri, pieces) => {
    if (!lineItems.length) {
      return [];
    }

    return _reduce(
      lineItems,
      (result, lineItem) => {
        const hydratedRecord = hydrateLineItemForRunNew(lineItem, materialsByUri, modelsByUri);
        if (!hydratedRecord) {
          return result;
        }

        // Since loaded line items from 'line-item-with-available-for-run-prints' uri
        // differs from regular line item uris, checking for UUIDs instead
        const lineItemUUID = extractUuid(lineItem.uri);
        const lineItemPieces = pieces.filter(
          piece => extractUuid(piece.line_item) === lineItemUUID,
        );
        hydratedRecord.pieces = lineItemPieces.map(piece =>
          // Omit pieces due to race condition
          ({ ...piece, lineItem: _omit(hydratedRecord, ['pieces']) }),
        );
        result.push(hydratedRecord);
        return result;
      },
      [],
    );
  },
);

export const getRunEstimatesByRunUri = createSelector(
  [getRunEstimates],
  runEstimates => _keyBy(runEstimates, 'run'),
);

export const getRunMaterials = createSelector(
  [baseStateSelectors.getStateRunMaterial, getStateResources],
  (uuids, resources) => _map(uuids, uuid => resources[uuid]),
);

export const getRunMaterialsForRun = createSelector(
  [getPredicate, getRunMaterials],
  (run, runMaterials) => run && _find(runMaterials, ['run', run.uri]),
);

export const getBuildForRun = createSelector(
  [getPredicate, getBuilds],
  (run, builds) => run && _find(builds, ['run', run.uri]),
);

// Even though it is called `actuals` (with `s`) the return value is 1 object (or none)
export const getRunActualsForRun = createSelector(
  [getPredicate, getRunActualsByRunUri],
  (run, runActualsByRunUri) => run && runActualsByRunUri[run.uri],
);

export const getRunEstimateForRun = createSelector(
  [getPredicate, getRunEstimatesByRunUri],
  (run, runEstimatesByRunUri) => run && runEstimatesByRunUri[run.uri],
);

export const getPrintUrisGroupedByWorkChecklistLinkingUris = createSelector(
  [getRunPrints, baseStateSelectors.getStateUIWorkChecklistLinkingsGroups],
  (prints, linkings = []) => {
    // TODO: last minute issues with work_checklist_linkings being empty
    // and causing prints to be missing from printUrisGroupedByLinkingUri
    // for NOW before complete refactor, the print uris with empty checklists
    // are grouped in printUrisGroupedByLinkingUri in key printsWithEmptyChecklists
    const printUrisGroupedByLinkingUri = { printsWithEmptyChecklists: [] };
    linkings.forEach(({ related_uri, work_checklist_linkings }) => {
      if (_isEmpty(work_checklist_linkings)) {
        printUrisGroupedByLinkingUri.printsWithEmptyChecklists.push(related_uri);
      }
      work_checklist_linkings.forEach(({ uri }) => {
        if (printUrisGroupedByLinkingUri[uri]) {
          printUrisGroupedByLinkingUri[uri].push(related_uri);
        } else {
          printUrisGroupedByLinkingUri[uri] = [related_uri];
        }
      });
    });

    return printUrisGroupedByLinkingUri;
  },
);

export const getLineItemsForRun = createSelector(
  [getPredicate, getLineItems],
  (run, lineItems) => {
    if (!run) {
      return [];
    }

    const skippedLineItemsStatuses = new Set([
      LINE_ITEM_STATUS.NEW,
      LINE_ITEM_STATUS.CALCULATING_ESTIMATES,
      LINE_ITEM_STATUS.PENDING,
    ]);

    // Return only non-specimen line items, filtered by status and order
    return lineItems.filter(({ order: orderUri, status, type }) => type !== MODEL_LIBRARY_TYPES.SPECIMEN &&
        run.orders.includes(orderUri) &&
        !skippedLineItemsStatuses.has(status));
  },
);

export const getSpecimensForRun = createSelector(
  [getPredicate, getLineItems],
  (run, lineItems) => {
    if (!run) {
      return [];
    }

    // Return only specimens (that belong to the run.orders)
    return lineItems.filter(({ order: orderUri, type }) =>
      type === MODEL_LIBRARY_TYPES.SPECIMEN && run.orders.includes(orderUri));
  },
);

export const getAllLineItemsForRun = createSelector(
  [getPredicate, getLineItems],
  (run, lineItems) => {
    if (!run) {
      return [];
    }

    // Return only line items that belong to the run.orders
    return lineItems.filter(({ order: orderUri }) =>
      run.orders.includes(orderUri));
  },
);
