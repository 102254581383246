import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import MaterialBatchComponent from 'rapidfab/components/records/MaterialBatch';
import {
  getSamples,
  getMaterialsByUri,
  getBureauUri,
  getMaterialContainersForBatch,
  getUUIDResource,
  getMaterialsForPrinterType,
  isFeatureEnabled, getRouteUUID,
  getMaterialTestPanels,
  getMaterialBatchSensorDataFilteredByMaterialBatchUri,
  getMaterialTestInstructions,
  getMaterialTestInstructionsByUri,
  getMaterialTestUnits,
  getMaterialTestUnitsByUri,
  getMaterialTestOperations,
  getMaterialTestPanelsByUri,
  getMaterialTestInstructionReports,
  getMaterialTestInstructionReportsByUri,
  getMaterialTestOperationLinkings,
  getUsersByUri,
  getLocations,
  getSubLocations,
  getIsDebugModeEnabled, getPermanentContainers,
} from 'rapidfab/selectors';
import {
  getMaterialBatchActionsForBatch,
} from 'rapidfab/selectors/materialManagementSelectors';
import Loading from 'rapidfab/components/Loading';
import Alert from 'rapidfab/utils/alert';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import { useDispatch, useSelector } from 'react-redux';
import { MATERIAL_BATCH_ACTION_TYPES, FEATURES, API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import { FormattedMessage } from 'react-intl';
import _map from 'lodash/map';
import _isEmpty from 'lodash/isEmpty';
import _uniq from 'lodash/uniq';
import _filter from 'lodash/filter';
import { handleInitializeMaterialBatchSensorData } from 'rapidfab/dispatchers/sensor';

// Enums for operation-linking scopes.
const OPERATION_LINKING_SCOPES = {
  MATERIAL: 'material',
  TEST_PANEL: 'testPanel',
};

const MaterialBatchContainer = props => {
  const uuid = useSelector(state => getRouteUUID(state));
  const bureau = useSelector(state => getBureauUri(state));
  const isDebugModeEnabled = useSelector(getIsDebugModeEnabled);
  // `traceabilityReport` query param is used to scroll user to appropriate block
  // when clicking on actions on Material Genealogy page
  const scrollToTraceabilityReport = props.queryParams
    && props.queryParams.traceabilityReport === 'true';
  const batch = useSelector(state => getUUIDResource(state, uuid));
  // TODO: Change when UI for multiple lots is ready {getUUIDResource(state, extractUuid(batch.material_lot))}
  const batchActions = useSelector(state => getMaterialBatchActionsForBatch(state, batch));
  const containers = useSelector(state => getMaterialContainersForBatch(state, batch));
  const permanentContainers = useSelector(getPermanentContainers);
  const materialsByUri = useSelector(state => {
    if (batch) return getMaterialsByUri(state);
    return null;
  });
  const locations = useSelector(getLocations);
  const subLocations = useSelector(getSubLocations);

  const samples = useSelector(getSamples);
  const materialTestPanels = useSelector(getMaterialTestPanels);
  const materialTestPanelsByUri = useSelector(getMaterialTestPanelsByUri);
  const materialTestInstructions = useSelector(getMaterialTestInstructions);
  const materialTestInstructionsByUri = useSelector(getMaterialTestInstructionsByUri);
  const materialTestInstructionReports = useSelector(getMaterialTestInstructionReports);
  const materialTestInstructionReportsByUri = useSelector(getMaterialTestInstructionReportsByUri);
  const materialTestOperations = useSelector(getMaterialTestOperations);
  const materialTestOperationLinkings = useSelector(getMaterialTestOperationLinkings);
  const materialTestUnits = useSelector(getMaterialTestUnits);
  const materialTestUnitsByUri = useSelector(getMaterialTestUnitsByUri);
  const material = batch ? materialsByUri[batch.material] : null;

  const materialBatchSensorDataForMaterialBatch = useSelector(state =>
    getMaterialBatchSensorDataFilteredByMaterialBatchUri(state, batch?.uri));

  const lot = useSelector(state =>
    batch && batch.material_lots && getUUIDResource(state, extractUuid(batch.material_lots[0]))) || null;
  const printer = useSelector(state =>
    batch && batch.at_machine && getUUIDResource(state, extractUuid(batch.at_machine))) || null;
  const printerType = useSelector(state =>
    printer && getUUIDResource(state, extractUuid(printer.printer_type))) || null;
  const printerLocation = useSelector(state =>
    printer && getUUIDResource(state, extractUuid(printer.location))) || null;
  const printerTypeMaterials = useSelector(state =>
    printerType && getMaterialsForPrinterType(state, printerType)) || null;
  const isBatchActionSaving = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post.fetching);

  const subLocation = useSelector(
    state => batch?.sub_location && getUUIDResource(state, extractUuid(batch.sub_location)),
  );
  const usersByUri = useSelector(getUsersByUri);

  const isLoading = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.MATERIAL_BATCH].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_LOT].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list.fetching
    || state.ui.nautilus[API_RESOURCES.LOCATION].get.fetching
    || state.ui.nautilus[API_RESOURCES.MATERIAL].list.fetching,
  );

  const printerRelatedResourcesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PRINTER].get.fetching
    || state.ui.nautilus[API_RESOURCES.PRINTER_TYPE].get.fetching,
  );

  const isGeneralMFGLanguageEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.GENERAL_MFG_LANGUAGE));
  const isMaterialTestPanelFeatureEnabled = useSelector(state => isFeatureEnabled(state, FEATURES.MATERIAL_TEST_PANEL));
  const isMaterialManagementFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.MATERIAL_MANAGEMENT));
  const isSensorsFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.SENSORS));

  const [notes, setNotes] = useState(batch?.notes || '');
  const [permanentContainersLoading, setPermanentContainersLoading] = useState(false);
  const [testPanelCurrentlyEdited, setTestPanelCurrentlyEdited] = useState(null);
  const [selectedTestPanelUri, setSelectedTestPanel] = useState(null);
  const [showMaterialAddTestPanelModal,
    setShowMaterialAddTestPanelModal] = useState(false);

  const [sensorDataChartState, setSensorDataChartState] = useState({});
  const sensorDataLineChartStateCallback = state => setSensorDataChartState(state);

  const selected = {
    isLoading: isLoading && !permanentContainersLoading,
    uuid,
    batch,
    lot,
    printer,
    samples,
    printerType,
    printerTypeMaterials,
    printerLocation,
    locations,
    scrollToTraceabilityReport,
    bureau,
    material,
    containers,
    batchActions,
    isBatchActionSaving,
    isGeneralMFGLanguageEnabled,
    materialTestPanels,
    materialTestPanelsByUri,
    materialTestInstructions,
    materialTestOperations,
    materialTestOperationLinkings,
    materialTestUnits,
    materialTestUnitsByUri,
    materialTestInstructionReports,
    materialTestInstructionReportsByUri,
    materialTestInstructionsByUri,
    subLocation,
    subLocations,
    usersByUri,
    materialBatchSensorDataForMaterialBatch,
    isDebugModeEnabled,
    permanentContainers,
    permanentContainersLoading,
    printerRelatedResourcesFetching,
  };

  const dispatch = useDispatch();
  const onInitialize = (currentUUID, currentBureau) => {
    if (currentUUID) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH]
        .get(currentUUID, {}, {}, {}, true))
        .then(response => {
          const {
            uri: batchUri,
            containers: currentContainers,
            material_lots: lotUris,
            at_machine: printerUri,
            sub_location: subLocationUri,
          } = response?.json;

          if (batchUri) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].list({ source_batch: batchUri }))
              .then(actionsResponse => {
                const resources = actionsResponse?.json?.resources || [];

                const userUris = _uniq(_map(resources, 'user'));

                const relocateBatchActions = _filter(resources, { action_type: 'relocate' });

                if (relocateBatchActions.length) {
                  const relocateActionsMetadata = _map(relocateBatchActions, 'metadata');

                  const destinationLocationUris = _map(relocateActionsMetadata, 'destination_location');
                  const destinationSublocationUris = _map(relocateActionsMetadata, 'destination_sub_location');

                  // Fetch location/sub-locations for relocation batch actions
                  dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].list({ uri: destinationLocationUris }));
                  dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].list({ uri: destinationSublocationUris }));
                }

                if (userUris.length) {
                  dispatch(Actions.Api.nautilus[API_RESOURCES.USERS].list({ uri: userUris }));
                }
              });
          }

          if (currentContainers.length) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER]
              .list({ uri: currentContainers }, { limit: 50 }));
          }

          // TODO: Change when UI for multiple lots is ready {get(extractUuid(lotUri)))}
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_LOT].get(extractUuid(lotUris[0])));
          if (printerUri) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER].get(extractUuid(printerUri)))
              .then(printerResp => {
                if (printerResp.json) {
                  const {
                    printer_type: printerTypeUri,
                    location: locationUri,
                  } = printerResp.json;
                  dispatch(Actions.Api.nautilus[API_RESOURCES.LOCATION].get(extractUuid(locationUri)));
                  dispatch(Actions.Api.nautilus[API_RESOURCES.PRINTER_TYPE].get(extractUuid(printerTypeUri)))
                    .then(printerTypeResp => {
                      const materials = printerTypeResp?.json?.materials;
                      if (materials) {
                        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ uri: materials }));
                      }
                    });
                }
              });
          }

          if (subLocationUri) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.SUB_LOCATION].get(extractUuid(subLocationUri)));
          }
        });
    }
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL].list({ bureau: currentBureau }));
  };

  useEffect(() => {
    if ([isMaterialManagementFeatureEnabled, isSensorsFeatureEnabled, batch].every(Boolean)) {
      handleInitializeMaterialBatchSensorData(dispatch, batch.uri, sensorDataChartState.difference, 0, true);
    }
  }, [batch, JSON.stringify(sensorDataChartState)]);

  const onInitializeMaterialTest = (materialTestPanelUri = null) => {
    if (isMaterialTestPanelFeatureEnabled && batch?.materials?.length) {
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].clear());
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING].list(
        { related_uri: materialTestPanelUri || batch?.materials[0]?.uri },
        {}, {}, {}, true,
      ))
        .then(operationLinkingsResponse => {
          const operationLinkings = operationLinkingsResponse.json?.resources;
          if (!_isEmpty(operationLinkings)) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION].list({
              material_test_operation: _map(operationLinkings, 'material_test_operation'),
              material_work_checklist: _map(operationLinkings, 'material_work_checklist'),
            }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }))
              .then(() => {
                const materialTestOperationUris = _map(
                  operationLinkings,
                  'material_test_operation');
                if (materialTestOperationUris.length) {
                  dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION].list({
                    uri: materialTestOperationUris,
                  }));
                }
              });
          }
        });
      dispatch(Actions.Api.nautilus[API_RESOURCES.SAMPLE].clear());
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].clear());
      dispatch(Actions.Api.nautilus[API_RESOURCES.SAMPLE].list({
        material_batch: batch?.uri,
      }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT })).then(samplesResponse => {
        const samples = samplesResponse.json?.resources;
        if (samples.length) {
          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_PANEL].list({
            sample: _map(samples, 'uri'),
          }, { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }))
            .then(materialTestPanelResponse => {
              const panels = materialTestPanelResponse.json?.resources;
              const materialTestPanelUris = _map(panels, 'uri');

              if (!_isEmpty(materialTestPanelUris)) {
                dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_INSTRUCTION_REPORT].list(
                  { material_test_panel: materialTestPanelUris },
                  { limit: PAGINATION_IGNORE_DEFAULT_LIMIT },
                ));
              }
            });
        }
      });

      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_UNIT].list());
    }
  };

  const onSaveNotes = (currentUUID, notes) => {
    const payload = { notes };
    dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].put(currentUUID, payload))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(currentUUID));
        Alert.success(<FormattedMessage
          id="toaster.notes.updated"
          defaultMessage="Notes successfully updated"
        />);
      });
  };

  const onEditBatchQuantityAction = ({ uri, updatedContainers, notes, quantity, reasonCode }) => {
    const payload = {
      action_type: MATERIAL_BATCH_ACTION_TYPES.EDIT_BATCH_QUANTITY,
      source_batch: uri,
      notes,
    };

    if (updatedContainers && !quantity) {
      payload.metadata = {
        containers: updatedContainers,
        reason_code: reasonCode,
      };
    } else {
      payload.metadata = {
        quantity,
        reason_code: reasonCode,
      };
    }

    return dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].post(payload))
      .then(() => {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH].get(extractUuid(uri))).then(response => {
          const {
            containers: responseContainers,
            material_in_containers: materialInContainers,
          } = response.json;

          dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_BATCH_ACTION].list({ source_batch: uri }));

          if (materialInContainers) {
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].clear('list'));
            dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list({ uri: responseContainers }));
          }
        });
        Alert.success(<FormattedMessage
          id="toaster.materialBatch.containersSuccessfullyUpdated"
          defaultMessage="Containers successfully updated"
        />);
      });
  };
  const onChangeNotes = useCallback(event => setNotes(event.target.value), [setNotes]);

  const fetchPermanentContainers = async () => {
    setPermanentContainersLoading(true);
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].clear('list'));
    await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_CONTAINER].list(
      { quantity: 0, disposable: false },
      {},
      {},
      {},
      true));
    setPermanentContainersLoading(false);
  };

  /**
   * A less-ideal workaround: This function tries to determine whether operation-linkings are
   * at test panel level or material level.
   */
  const determineScopeForOperationLinkings = async () => {
    if (selectedTestPanelUri) {
      const response =
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING]
          .list({ related_uri: selectedTestPanelUri }, {}, {}, {}, true));
      const testPanelLevelOperationLinkings = response.json?.resources;
      const testPanelLevelOperationLinkingUuids = _map(testPanelLevelOperationLinkings, ({ uri }) => extractUuid(uri));
      if (testPanelLevelOperationLinkings.length) {
        return { scope: OPERATION_LINKING_SCOPES.TEST_PANEL, testPanelLevelOperationLinkingUuids };
      }
    }
    return { scope: OPERATION_LINKING_SCOPES.MATERIAL, testPanelLevelOperationLinkingUuids: null };
  };

  useEffect(() => onInitialize(uuid, bureau), [uuid]);
  useEffect(() => {
    const { scope: operationLinkingScope, testPanelLevelOperationLinkingUuids } =
      determineScopeForOperationLinkings();
    if (testPanelLevelOperationLinkingUuids) {
      // Clear the fetched operation-linkings as we only needed them to determine scope.
      dispatch(Actions.Api.nautilus[API_RESOURCES.MATERIAL_TEST_OPERATION_LINKING]
        .remove(testPanelLevelOperationLinkingUuids));
    }
    if (operationLinkingScope === OPERATION_LINKING_SCOPES.MATERIAL) {
      // This will initialise with the first material in the batch's URI.
      onInitializeMaterialTest();
    } else {
      onInitializeMaterialTest(selectedTestPanelUri);
    }
  },
  [uuid, batch?.materials?.length, isMaterialTestPanelFeatureEnabled, selectedTestPanelUri]);

  if ((isLoading && !permanentContainersLoading) || !batch || !lot) {
    return (<Loading />);
  }

  const dispatched = {
    onInitializeMaterialTest,
    sensorDataLineChartStateCallback,
    fetchPermanentContainers,
  };

  return (
    <MaterialBatchComponent
      {...props}
      {...selected}
      {...dispatched}
      notes={notes}
      onSaveNotes={onSaveNotes}
      onChangeNotes={onChangeNotes}
      onEditBatchQuantityAction={onEditBatchQuantityAction}
      isMaterialTestPanelFeatureEnabled={isMaterialTestPanelFeatureEnabled}
      materialAddTestPanelModalState={{
        showMaterialAddTestPanelModal,
        setShowMaterialAddTestPanelModal,
        testPanelCurrentlyEdited,
        setTestPanelCurrentlyEdited,
      }}
      manageTestPanelsState={{
        selectedTestPanelUri,
        setSelectedTestPanel,
      }}
    />
  );
};

MaterialBatchContainer.propTypes = {
  uuid: PropTypes.string.isRequired,
  batch: PropTypes.shape({}),
  lot: PropTypes.shape({}),
  bureau: PropTypes.string.isRequired,
  onInitialize: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  queryParams: PropTypes.shape({
    traceabilityReport: PropTypes.string,
  }).isRequired,
};

MaterialBatchContainer.defaultProps = {
  batch: null,
  lot: null,
};

export default MaterialBatchContainer;
