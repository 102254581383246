import { faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import ResourcesModalWithScrollPagination
  from 'rapidfab/components/records/run/ResourcesModalWithScrollPagination';
import { API_RESOURCES, ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import React from 'react';
import { useSelector } from 'react-redux';

const BuildPlateLineItemsModeIncompatiblePiecesContainer = ({
  isVisible,
  onClose,
  reworkedPieces,
  fetchMorePieces,
  lineItemUri,
}) => {
  const reworkingPiecesFetching = useSelector(state =>
    state.ui.nautilus[API_RESOURCES.PIECES_BY_RUN].list.fetching);

  return (
    <ResourcesModalWithScrollPagination
      show={isVisible}
      fetchResourceUri={lineItemUri}
      onClose={onClose}
      resourcesList={reworkedPieces}
      resourcesListFetching={reworkingPiecesFetching}
      fetchMoreResources={fetchMorePieces}
      title="Incompatible Pieces"
      content={(
        reworkedPieces.map(({ piece_name, piece }, index) => (
          <div key={piece.uri} className="d-flex align-items-center spacer-bottom mb5">
            <FontAwesomeIcon icon={faWrench} className="spacer-right" />
            <a
              href={getRouteURI(ROUTES.PIECE_EDIT, { uuid: extractUuid(piece) })}
              target="_blank"
              rel="noopener noreferrer"
              className="font-size-16 spacer-right"
            >
              {piece_name} ({index + 1})
            </a>
          </div>
        ))
      )}
    />
  );
};
BuildPlateLineItemsModeIncompatiblePiecesContainer.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  reworkedPieces: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  fetchMorePieces: PropTypes.func.isRequired,
  lineItemUri: PropTypes.string.isRequired,
};

export default BuildPlateLineItemsModeIncompatiblePiecesContainer;
