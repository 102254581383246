import React from 'react';
import PropTypes from 'prop-types';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid } from 'rapidfab/utils/uuidUtils';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { ROUTES } from 'rapidfab/constants';
import { Link } from 'react-router-dom';

const MaterialInfo = ({ material }) => {
  if (!material) {
    return null;
  }
  return (
    <ResourceReadOnlyView
      withPanelWrapper
      title="Material Type"
      entity={material}
      isDarken
    >
      <ResourceReadOnlyViewRow
        name="name"
        className="wrap-text"
        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
        customValue={(
          <Link
            to={getRouteURI(ROUTES.MATERIALS, null, { uuid: extractUuid(material.uri) }, true)}
          >
            {material.name}
          </Link>
        )}
      />
      <ResourceReadOnlyViewRow name="type" />
      <ResourceReadOnlyViewRow name="units" />
      <ResourceReadOnlyViewRow
        name="cost"
        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.COST}
      />
    </ResourceReadOnlyView>
  );
};

MaterialInfo.defaultProps = {
  material: null,
};

MaterialInfo.propTypes = {
  material: PropTypes.shape({
    uri: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    units: PropTypes.string.isRequired,
    cost: PropTypes.number.isRequired,
  }),
};

export default MaterialInfo;
