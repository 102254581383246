import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import useElementScroll, { isScrolledToBottom } from 'rapidfab/hooks/useElementScroll';
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';

const ResourcesModalWithScrollPagination = ({
  show,
  onClose,
  resourcesList,
  resourcesListFetching,
  fetchMoreResources,
  fetchResourceUri,
  title,
  content,
}) => {
  const [loading, setLoading] = useState(false);

  const [calledForMore, setCalledForMore] = useState(false);

  const anchor = useRef();
  const scrollPosition = useElementScroll(anchor);
  const scrolledToBottom = isScrolledToBottom(anchor, scrollPosition, 20);

  useEffect(() => {
    if (
      !calledForMore && fetchMoreResources
      && (scrolledToBottom && resourcesList.length && !resourcesListFetching)
      && !loading) {
      setLoading(true);
      setCalledForMore(true);
      fetchMoreResources(fetchResourceUri)
        .finally(() => setLoading(false));
    }
  }, [scrolledToBottom, calledForMore, loading, resourcesListFetching]);

  useEffect(() => {
    if (calledForMore && !loading) {
      setCalledForMore(false);
    }
  }, [loading]);

  return (
    <Modal
      size="sm"
      show={show}
      onHide={onClose}
      backdrop="static"
      className="quoteProcessStepModalShow incompatible-pieces-modal"
      dialogClassName="line-item-quote-modal"
      backdropClassName="incompatible-pieces-backdrop"
    >
      <div
        ref={anchor}
        style={{
          overflowY: 'auto',
          maxHeight: '600px',
        }}
      >
        <Modal.Header>
          <Modal.Title className="w-100 d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <p className="custom-darken-modal-title-text mt0 ml0">
                {title}
              </p>
            </div>
            <FontAwesomeIcon
              icon={faClose}
              onClick={onClose}
              tabIndex={0}
              role="button"
              className="custom-darken-modal-button"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(resourcesListFetching && !resourcesList.length)
            ? <Loading />
            : content}
          {loading && (
            <div style={{ padding: '20px' }}>
              <Loading />
            </div>
          )}
        </Modal.Body>
      </div>
    </Modal>
  );
};

ResourcesModalWithScrollPagination.propTypes = {
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  resourcesList: PropTypes.arrayOf(PropTypes.shape({
    piece_name: PropTypes.string,
    piece: PropTypes,
  })).isRequired,
  resourcesListFetching: PropTypes.bool.isRequired,
  fetchMoreResources: PropTypes.func.isRequired,
  fetchResourceUri: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};

export default ResourcesModalWithScrollPagination;
