import React, { memo, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, {
  RESOURCE_READ_ONLY_VIEW_FIELD_TYPES,
} from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import { ROUTES } from 'rapidfab/constants';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import { Link } from 'react-router-dom';
import { faCubes, faExternalLink, faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import FormattedWeight from 'rapidfab/components/FormattedWeight';
import PieChart from '../../ChartPie';

const LotDetailsPanelHeader = () => (
  <Row className="d-flex align-items-center">
    <Col xs={6}>
      Lot Details
    </Col>
  </Row>
);

const LotDetails = props => {
  const { lot, batch, isDarken } = props;

  const [selectedSegment, setSelectedSegment] = useState(0);
  const currentBatchMaterial = batch?.materials[selectedSegment];

  const materials = useMemo(() => ([...batch?.materials]), [batch?.materials]);

  return (
    <Card bg="dark" className={`mb15 ${isDarken ? 'custom-darken-modal--card' : ''}`}>
      <Card.Header className={`${isDarken ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
        <LotDetailsPanelHeader />
      </Card.Header>
      <div className={`${isDarken ? '' : 'card-body-wrapper'}`}>
        <Card.Body className={`${isDarken ? 'custom-darken-modal--card-body' : ''}`}>
          <Row>
            <Col xs={6} sm={3}>
              <PieChart
                materials={materials}
                onSegmentClick={(_, element) => {
                  setSelectedSegment(element.index);
                }}
              />
            </Col>
            <Col xs={6} sm={9}>
              <Button
                variant="light"
                style={{ width: '100%' }}
                className={`${isDarken ? 'custom-darken-modal--button' : ''}`}
              >
                <p className="mb0">{lot?.material_name}</p>
              </Button>
              <Row>
                <Col xs={6} sm={6}>
                  <Button
                    variant="light"
                    style={{ width: '100%', margin: '10px 0' }}
                    className={`${isDarken ? 'custom-darken-modal--button' : ''}`}
                  >
                    <Link
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      className={`${isDarken ? 'custom-darken-modal--button-link' : ''}`}
                      to={getRouteURI(ROUTES.MATERIAL_LOT,
                        { uuid: extractUuid(currentBatchMaterial?.material_lot_uri) },
                        {},
                        true)}
                    >
                      <FontAwesomeIcon icon={faObjectGroup} size="lg" />
                      <p className="mb0">Material Lot ({getShortUUID(lot?.uuid)})</p>
                      <FontAwesomeIcon icon={faExternalLink} size="lg" />
                    </Link>
                  </Button>
                  <ResourceReadOnlyView entity={currentBatchMaterial}>
                    <p><strong>Name:</strong> {lot?.name}</p>
                    <ResourceReadOnlyViewRow name="percentage" />
                  </ResourceReadOnlyView>
                  <ResourceReadOnlyView entity={lot}>
                    <ResourceReadOnlyViewRow
                      name="actual_delivery_date"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                    <ResourceReadOnlyViewRow name="delivery_id" />
                    <ResourceReadOnlyViewRow
                      name="expiration_date"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                    <ResourceReadOnlyViewRow
                      name="usage_cycles_limit"
                      type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATE}
                    />
                  </ResourceReadOnlyView>
                </Col>
                <Col xs={6} sm={6}>
                  <Button
                    variant="light"
                    style={{
                      margin: '10px 0',
                      width: '100%',
                    }}
                    className={`${isDarken ? 'custom-darken-modal--button' : ''}`}
                  >
                    <a
                      style={{
                        width: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                      className={`${isDarken ? 'custom-darken-modal--button-link' : ''}`}
                      href={getRouteURI(ROUTES.MATERIAL_BATCH, {
                        uuid: batch?.is_initial_batch ?
                          batch?.uuid :
                          extractUuid(batch?.initial_batch),
                      })}
                    >
                      <FontAwesomeIcon icon={faCubes} size="lg" />
                      <p className="mb0">
                        Material Batch
                        ({getShortUUID(batch?.is_initial_batch ? batch?.uuid : extractUuid(batch?.initial_batch))})
                      </p>
                      <FontAwesomeIcon icon={faExternalLink} size="lg" />
                    </a>
                  </Button>
                  <ResourceReadOnlyView entity={batch}>
                    <p>
                      <strong>Quantity:</strong>
                      <FormattedWeight
                        value={batch?.quantity}
                        valueUnits={currentBatchMaterial?.units}
                      />
                    </p>
                    <p><strong>Number of Containers:</strong> {batch?.containers?.length}</p>
                    <ResourceReadOnlyViewRow name="usage_cycles" />
                  </ResourceReadOnlyView>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card.Body>
      </div>
    </Card>
  );
};

LotDetails.defaultProps = {
  isDarken: false,
};

LotDetails.propTypes = {
  batch: PropTypes.shape({
    uuid: PropTypes.string.isRequired,
    is_initial_batch: PropTypes.bool,
    initial_batch: PropTypes.string,
    quantity: PropTypes.number.isRequired,
    containers: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
    materials: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }).isRequired,
  lot: PropTypes.shape({
    uuid: PropTypes.string,
    name: PropTypes.string,
    material_name: PropTypes.string,
  }).isRequired,
  material: PropTypes.shape({
    materials: PropTypes.arrayOf(PropTypes.shape({
      material_name: PropTypes.string,
    })),
  }).isRequired,
  isDarken: PropTypes.bool,
};

export default memo(LotDetails);
