import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'react-bootstrap';
import { faAngleDown, faAngleUp, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const PositionTable = ({
  data,
  headerRowItems,
  renderDataRowItemsCallback,
  currentActivePrepTaskIndex,
  onMoveUp,
  onMoveDown,
  showEditColumn,
  showDeleteColumn,
  onEdit,
  onDelete,
  emptyDataPlaceholder,
}) => {
  const arrowStyles = {
    textAlign: 'center',
    padding: '.2rem',
    border: 'none',
  };

  // Number of header rows + Position + Edit (if shown) + Delete (if shown)
  const columnsCount = headerRowItems.length + 1 + showEditColumn + showDeleteColumn;

  return (
    <Table className="text-black">
      <thead>
        <tr>
          <th style={{ width: '5%' }}>Position</th>
          {headerRowItems.map(
            // eslint-disable-next-line react/no-array-index-key
            (headerRowItem, index) => <th key={index}>{headerRowItem}</th>,
          )}
          {showEditColumn && <th style={{ width: '5%' }}>Edit</th>}
          {showDeleteColumn && <th style={{ width: '5%' }}>Delete</th>}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && (
          <tr>
            <td colSpan={columnsCount}>
              {emptyDataPlaceholder}
            </td>
          </tr>
        )}
        {data.map((item, index) => {
          // 0 is valid value, so we always need to check for non-null value
          const isTaskDisabled =
            (currentActivePrepTaskIndex !== null) && currentActivePrepTaskIndex >= index;
          const disabledTitle = "This task is active, so can't be updated";

          const canMoveTaskDown = (!isTaskDisabled && (index !== data.length - 1));
          const canMoveTaskUp =
            !isTaskDisabled &&
            (index !== 0) &&
            // If previous task is active, so user shouldn't be able to swap these tasks
            // Validation with null comparison as 0 – is valid index
            (currentActivePrepTaskIndex === null || ((index - 1) > currentActivePrepTaskIndex));

          return (
            <tr
              key={item.uuid || item.key}
              className={isTaskDisabled && 'disabled'}
              title={isTaskDisabled && disabledTitle}
            >
              <td>
                <div className="table-button-group">
                  {
                    canMoveTaskDown ? (
                      <Button
                        onClick={() => onMoveDown(index)}
                        variant="default"
                        style={arrowStyles}
                        tabIndex={0}
                      >
                        <FontAwesomeIcon icon={faAngleDown} size="2x" />
                      </Button>
                    ) : (
                      // Draw and empty div to position `up` arrow button correctly
                      <div style={{ ...arrowStyles, display: 'inline-block' }} />
                    )
                  }
                  {canMoveTaskUp && (
                    <Button
                      onClick={() => onMoveUp(index)}
                      variant="default"
                      style={arrowStyles}
                      tabIndex={0}
                    >
                      <FontAwesomeIcon icon={faAngleUp} size="2x" />
                    </Button>
                  )}
                </div>
              </td>
              {renderDataRowItemsCallback(item)}
              {showEditColumn && (
                <td className="text-center">
                  {
                    !isTaskDisabled && (
                      <div
                        role="button"
                        onClick={() => {
                          onEdit(index);
                        }}
                        tabIndex={0}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </div>
                    )
                  }
                </td>
              )}
              {showDeleteColumn && (
                <td className="text-center">
                  {
                    !isTaskDisabled && (
                      <div
                        role="button"
                        onClick={() => onDelete('tasks_in_workflow', index)}
                        tabIndex={0}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </div>
                    )
                  }
                </td>
              )}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

PositionTable.defaultProps = {
  showEditColumn: false,
  showDeleteColumn: false,
  onEdit: () => {},
  onDelete: () => {},
  emptyDataPlaceholder: null,
  currentActivePrepTaskIndex: null,
};

PositionTable.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      uuid: PropTypes.string,
      key: PropTypes.string,
    }),
  ])).isRequired,
  currentActivePrepTaskIndex: PropTypes.number,
  headerRowItems: PropTypes.arrayOf(PropTypes.node).isRequired,
  renderDataRowItemsCallback: PropTypes.func.isRequired,
  onMoveUp: PropTypes.func.isRequired,
  onMoveDown: PropTypes.func.isRequired,
  showEditColumn: PropTypes.bool,
  showDeleteColumn: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  emptyDataPlaceholder: PropTypes.node,
};

export default PositionTable;
