import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { LOCATION_NOTIFICATION_SETTING_BOOLS } from 'rapidfab/constants';
import { LOCATION_NOTIFICATION_SETTINGS_BOOLS_MAP } from 'rapidfab/mappings';
import React from 'react';
import { Card, Col, Form as BSForm, FormGroup, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

const LocationSettings = ({
  allEmailNotificationsActive,
  handleEmailNotificationsModeSetting,
  updatedLocationSettings,
  onChangeLocationSettings,
  isCustomDarkCardStyle,
  disabled,
}) => (
  <Card
    bg={`${isCustomDarkCardStyle ? '' : 'dark'}`}
    className={`mb15 ${isCustomDarkCardStyle ? 'custom-darken-modal--card' : ''}`}
  >
    <Card.Header className={`${isCustomDarkCardStyle ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>
      <Row className="d-flex justify-content-between align-items-center">
        <Col xs={7}>
          <span>Email Notification Settings</span>
          <OverlayTrigger
            placement="top"
            overlay={(
              <Tooltip>
                The location contacts should receive the following email notifications.
              </Tooltip>
            )}
          >
            <FontAwesomeIcon className="spacer-left" icon={faInfoCircle} />
          </OverlayTrigger>
        </Col>
        <Col className="p-0">
          <div className="d-flex align-items-center pull-right">
            Enable All

            <BSForm.Check
              type="switch"
              id="custom-switch"
              disabled={disabled}
              checked={allEmailNotificationsActive}
              onChange={handleEmailNotificationsModeSetting}
              className="ml15 mr0"
            />
          </div>
        </Col>
      </Row>
    </Card.Header>
    <Card.Body
      className={`${isCustomDarkCardStyle ? 'custom-darken-modal--card-body' : 'card-body-wrapper'}`}
    >
      {Object.values(LOCATION_NOTIFICATION_SETTING_BOOLS)
        .map(key => {
          const value = updatedLocationSettings[key];
          const translationObject =
            LOCATION_NOTIFICATION_SETTINGS_BOOLS_MAP[key];

          return (
            <FormGroup key={key} className="form-group mb-0" controlId={key}>
              <Field
                name={key}
                type="checkbox"
                render={() => (
                  <BSForm.Check
                    disabled={disabled}
                    checked={value}
                    onChange={() => onChangeLocationSettings(key, !value)}
                    className="m-l-0 mr15"
                    type="checkbox"
                    label={(<FormattedMessage {...translationObject} />)}
                  />
                )}
              />
            </FormGroup>
          );
        })}
    </Card.Body>
  </Card>
);

LocationSettings.propTypes = {
  allEmailNotificationsActive: PropTypes.bool.isRequired,
  handleEmailNotificationsModeSetting: PropTypes.func.isRequired,
  updatedLocationSettings: PropTypes.shape({}).isRequired,
  onChangeLocationSettings: PropTypes.func.isRequired,
  isCustomDarkCardStyle: PropTypes.bool,
  disabled: PropTypes.bool,
};

LocationSettings.defaultProps = {
  isCustomDarkCardStyle: false,
  disabled: false,
};

export default LocationSettings;
