import React, { useMemo } from 'react';
import { Button, Card, Image } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useModal } from 'rapidfab/hooks';
import { CAROUSEL_ALLOWED_EXTENSIONS, MODAL_TEMPLATES } from 'rapidfab/constants';
import Loading from 'rapidfab/components/Loading';

const Carousel = ({
  carousel,
  onManageCarouselClick,
  focusedCarouselImageState: [focusedCarouselImage, setFocusedCarouselImage],
  handleUploadCarouselImage,
  isCarouselFetching,
  uploadingCarouselImages,
  handleFileUploadRejection,
}) => {
  const carouselSnapshots = useMemo(() => carousel?.snapshots || [], [JSON.stringify(carousel?.snapshots)]);
  const [openUploadModal, UploadModal] = useModal();

  const handleOpenUploadModal = () => openUploadModal({
    modalTemplate: MODAL_TEMPLATES.UPLOAD_FILE,
    title: 'Upload Carousel Image',
    onUploadConfirm: handleUploadCarouselImage,
    onDropRejected: handleFileUploadRejection,
    acceptedExtensions: CAROUSEL_ALLOWED_EXTENSIONS.map(extension => `image/${extension}`),
  });

  const handleCarouselPan = direction => {
    const carouselImagesElement = document.querySelector('#carouselImages');

    if (direction === 'left') {
      carouselImagesElement.scroll({
        left: carouselImagesElement.scrollLeft - 200,
        behavior: 'smooth',
      });
    } else if (direction === 'right') {
      carouselImagesElement.scroll({
        left: carouselImagesElement.scrollLeft + 200,
        behavior: 'smooth',
      });
    } else {
      /* ... */
    }
  };
  return (
    <>
      <UploadModal id="carouselUploadFileModal" />,
      <Card bg="light">
        <Card.Header className="pd-exp inverse">
          <Button
            bg="primary"
            variant="primary"
            onClick={onManageCarouselClick}
            className="pull-right"
            size="xs"
          >
            <FontAwesomeIcon
              className="spacer-right"
              icon={faEdit}
            />
            Manage
          </Button>
        </Card.Header>
        <div className=" card-body-wrapper">
          <Card.Body className="pd-exp inverse">
            <div id="carouselContentWrapper" className="d-flex align-items-center justify-content-between">
              <FontAwesomeIcon
                role="button"
                color="#555555"
                onClick={() => handleCarouselPan('left')}
                className="mr15"
                icon={faChevronLeft}
                size="2x"
              />
              <div id="carouselImages" className="d-flex" style={{ overflowX: 'scroll', gap: 10 }}>
                {carouselSnapshots?.map((imageItem, index) => (
                  <Image
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    onClick={() => setFocusedCarouselImage(imageItem)}
                    width={100}
                    height={100}
                    style={{
                      objectFit: 'contain',
                      borderRadius: 12,
                      border: focusedCarouselImage?.snapshot === imageItem.snapshot ?
                        '2px solid #1ca8dd' : '2px solid #e6e6e6',
                    }}
                    src={imageItem.content}
                  />
                ))}
                {(uploadingCarouselImages || isCarouselFetching) ? (
                  <div className="d-flex align-items-center justify-content-center" style={{ width: '100px' }}>
                    <Loading />
                  </div>
                ) : (
                  <div role="button" tabIndex={0} className="add-carousel-image-btn" onClick={handleOpenUploadModal}>
                    <FontAwesomeIcon icon={faPlus} size="3x" className="add-carousel-image-icon" />
                  </div>
                )}
              </div>
              <FontAwesomeIcon
                role="button"
                color="#555555"
                onClick={() => handleCarouselPan('right')}
                className="ml15"
                icon={faChevronRight}
                size="2x"
              />
            </div>
          </Card.Body>
        </div>
      </Card>
    </>
  );
};

export default Carousel;

Carousel.propTypes = {
  onManageCarouselClick: PropTypes.func.isRequired,
  carousel: PropTypes.shape({
    snapshots: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  focusedCarouselImageState: PropTypes.shape({
    focusedCarouselImage: PropTypes.string,
    setFocusedCarouselImage: PropTypes.func,
  }).isRequired,
  handleUploadCarouselImage: PropTypes.func.isRequired,
  handleFileUploadRejection: PropTypes.func.isRequired,
  isCarouselFetching: PropTypes.bool.isRequired,
  uploadingCarouselImages: PropTypes.bool.isRequired,
};
