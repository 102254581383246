import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import _capitalize from 'lodash/capitalize';

export function currentTime() {
  const date = new Date();
  return date.toISOString();
}

export const convertSecondsToHours = seconds => Number((seconds / 3600).toFixed(2));
export const convertHoursToSeconds = hours => Number((hours * 3600).toFixed(2));

export function pad(number) {
  if (number > -10 && number < 0) {
    return `-0${Math.abs(number)}`;
  }

  if (number >= 0 && number < 10) {
    return `0${number}`;
  }

  return `${number}`;
}

// Convert seconds into HH:mm:ss format
export function hhmmss(secs, padHours = false) {
  const parsedSeconds = Math.ceil(Number.parseFloat(secs));

  if (!parsedSeconds) {
    return `${padHours ? '00' : '0'}:00:00`;
  }

  const hours = Math.floor(parsedSeconds / 3600);
  const minutes = Math.floor((parsedSeconds % 3600) / 60);
  const seconds = parsedSeconds % 60;

  return `${padHours ? pad(hours) : hours}:${pad(minutes)}:${pad(seconds)}`;
}

// parse hh:mm:ss to object
export const parseHhmmss = timeString => {
  if (!timeString) {
    return {
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  const parts = timeString.split(':');

  return {
    hours: Number(parts[0]) || 0,
    minutes: Number(parts[1]) || 0,
    seconds: Number(parts[2]) || 0,
  };
};

/* eslint-disable linebreak-style */
export const isInHoursMinutesFormat = value => {
  if (typeof value !== 'string') {
    return false;
  }
  const regex = new RegExp('^(\\d?\\d\\d):[0-5]\\d$');
  const match = value.match(regex);
  return (match !== null);
};

export const getHoursAndMinutesFromDurationSecs = duration => {
  if (duration === undefined) {
    return '00:00';
  }
  if (duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.round((duration % 3600) / 60);
    const hoursAsString = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesAsString = minutes < 10 ? `0${minutes}` : `${minutes}`;
    return `${hoursAsString}:${minutesAsString}`;
  }
  return '00:00';
};

export const getPostProcessorDurationInSpecialFormat = duration => {
  if (duration === undefined) {
    return '0h 0m';
  }
  if (duration) {
    const hours = Math.floor(duration / 3600);
    const minutes = Math.round((duration % 3600) / 60);
    // Format hours and minutes, including "0h" if no hours
    const hoursAsString = `${hours}h`;
    const minutesAsString = minutes > 0 ? `${minutes}m` : '0m';
    return `${hoursAsString} ${minutesAsString}`;
  }
  return '0h 0m';
};

export const getDurationSecondsFromHoursAndMinutes = hoursAndMinutes => {
  const [hours, minutes] = hoursAndMinutes.split(':');
  const duration = (+hours * 3600) + (+minutes * 60);
  return duration;
};

export function toISOStringWithoutTime(date) {
  if (date instanceof Date) {
    return date.toISOString().split('T')[0];
  }
  throw new Error(`${date} was provided to "toISOStringWithoutTime" which is not a Date`);
}

export function getLaborWeekdaysTimeMapping(weekDays) {
  const weekDaysTimeMapping = {};

  dayjs.extend(objectSupport);
  weekDays.forEach(day => {
    weekDaysTimeMapping[day] = {
      start: dayjs({
        h: 9,
        m: 0,
      }).format('HH:mm'),
      finish: dayjs({
        h: 17,
        m: 0,
      }).format('HH:mm'),
    };
  });

  return weekDaysTimeMapping;
}

export function transformedLaborWorkScheduleWeekData(workSchedule) {
  return Object.assign({},
    ...Object.entries(workSchedule.week).map(([day, time]) => {
      const { start, finish } = time;

      return {
        [_capitalize(day)]: {
          start: dayjs({
            h: start.h,
            m: start.m,
          }).format('HH:mm'),
          finish: dayjs({
            h: finish.h,
            m: finish.m,
          }).format('HH:mm'),
        },
      };
    }));
}

export function defaultLaborWorkScheduleWeekData(weekDaysTimeMapping, weekData) {
  return Object.assign({},
    ...Object.entries(weekDaysTimeMapping).map(([day, time]) => {
      const { start, finish } = time;
      if (!Object.keys(weekData).includes(day)) {
        return {
          [day]: {
            start,
            finish,
          },
        };
      }
      return null;
    }).filter(Boolean));
}

/**
 * Converts a duration from seconds to days.
 * @param {number} duration - The duration in seconds.
 * @return {number} The duration in days.
 */
export function convertToDays(duration) {
  return duration / (24 * 60 * 60);
}

/**
 * Formats a given duration in seconds into a human-readable string,
 * rounding to the nearest second, minute, or hour depending on the input value.
 *
 * If the duration is less than a minute, it will return the time in seconds.
 * If the duration is less than an hour but greater than a minute, it will return the time in minutes.
 * If the duration is greater than an hour, it will return the time in hours.
 *
 * @param {number} seconds - The duration in seconds to be formatted.
 * @returns {string} - A string representing the formatted duration in seconds, minutes, or hours.
 */
export function formatQueueBufferDuration(seconds) {
  const duration = dayjs.duration(seconds, 'seconds');
  const totalMinutes = duration.asMinutes();
  const totalHours = duration.asHours();

  const pluralize = (value, unit) => `${value} ${unit}${value !== 1 ? 's' : ''}`;

  if (seconds < 60) {
    return `about ${pluralize(Math.round(seconds), 'second')}`;
  } if (totalMinutes < 60) {
    return `about ${pluralize(Math.round(totalMinutes), 'minute')}`;
  }
  return `about ${pluralize(Math.round(totalHours), 'hour')}`;
}

/**
 * Calculates the duration between two timestamps and formats it as "HH:mm:ss".
 *
 * @param {string} startTime - The start timestamp in ISO 8601 format (e.g., "2024-10-25T17:17:09.016509+00:00").
 * @param {string} endTime - The end timestamp in ISO 8601 format (e.g., "2024-10-25T17:18:52.903709+00:00").
 * @returns {string|null} The formatted duration as "HH:mm:ss" if inputs are valid; otherwise, returns `null`.
 *
 * @throws {Error} Throws an error if the inputs are not valid strings or if the end time is before the start time.
 */
export function getRunActualsDurationForRun(startTime, endTime) {
  if (!startTime || !endTime) {
    return null;
  }

  // Validate timestamps
  if (!dayjs(startTime).isValid() || !dayjs(endTime).isValid()) {
    return null;
  }

  const start = dayjs(startTime);
  const end = dayjs(endTime);

  // Check if the end time is before the start time
  if (end.isBefore(start)) {
    throw new Error('End time cannot be earlier than start time.');
  }

  // Calculate the duration
  const diff = end.diff(start); // Difference in milliseconds

  // Format the duration as "HH:mm:ss"
  const formattedDuration = dayjs.duration(diff).format('HH:mm:ss');

  return formattedDuration;
}
