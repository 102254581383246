import { faChartLine, faTableList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import TraceabilityReportExportButton
  from 'rapidfab/components/records/material_management/TraceabilityReportExportButton';
import Table from 'rapidfab/components/Tables/Table';
import { FormattedMessage } from 'rapidfab/i18n';
import React from 'react';
import { Card, Col, Nav, NavItem, NavLink, Row } from 'react-bootstrap';

const TRACEABILITY_REPORT_TABS = {
  ACTION_LOG: 'action_log',
  SENSOR_HISTORY: 'sensor_history',
};

const MaterialBatchTraceabilityReport = ({
  tab,
  setTab,
  batch,
  batchActions,
  columns,
  isDarken,
}) => (
  <Card bg="dark" className={`mb15 ${isDarken ? 'custom-darken-modal--card' : ''}`}>
    <Card.Header className={`${isDarken ? 'pd-exp custom-darken-modal--card-header' : 'pd-exp inverse'}`}>Material Traceability Report</Card.Header>
    <div className={`${isDarken ? '' : 'card-body-wrapper'}`}>
      <Card.Body className={`${isDarken ? 'custom-darken-modal--card-body' : ''}`}>
        <Row className="mb15">
          <Col xs={{ span: 8 }} lg={{ span: 8 }}>
            <Nav
              variant="tabs"
              activeKey={tab}
              onSelect={setTab}
            >
              <NavItem>
                <NavLink eventKey={TRACEABILITY_REPORT_TABS.ACTION_LOG}>
                  <FontAwesomeIcon icon={faTableList} className="spacer-right" />
                  <FormattedMessage
                    id="tab.actionLog"
                    defaultMessage="Action Log"
                  />
                </NavLink>
              </NavItem>
              {/* The functionality is not implemented, so the tab is disabled */}
              <NavItem>
                <NavLink disabled eventKey={TRACEABILITY_REPORT_TABS.SENSOR_HISTORY}>
                  <FontAwesomeIcon icon={faChartLine} className="spacer-right" />
                  <FormattedMessage
                    id="tab.sensorHistory"
                    defaultMessage="Sensor History"
                  />
                </NavLink>
              </NavItem>
            </Nav>
          </Col>
          <Col xs={{ span: 4 }} lg={{ span: 4 }}>
            <TraceabilityReportExportButton batch={batch} />
          </Col>
        </Row>
        <Table
          data={batchActions}
          columns={columns}
          isFilteringEnabled={false}
          withDefaultPagination={false}
          isUpdatedColumnShown={false}
          initialSortedColumn="created"
          resetDefaultSortingState
        />
      </Card.Body>
    </div>
  </Card>
);

MaterialBatchTraceabilityReport.defaultProps = {
  isDarken: false,
};

MaterialBatchTraceabilityReport.propTypes = {
  tab: PropTypes.string.isRequired,
  setTab: PropTypes.func.isRequired,
  batch: PropTypes.shape({}).isRequired,
  batchActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDarken: PropTypes.bool,
};

export default MaterialBatchTraceabilityReport;
