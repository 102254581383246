import BureauBarcodeIcon from 'rapidfab/components/BureauBarcodeIcon';
import MaterialBatchTraceabilityReport from 'rapidfab/components/inventory/MaterialBatchTraceabilityReport';
import Loading from 'rapidfab/components/Loading';
import BatchPowderQualityStatusBadge
  from 'rapidfab/components/records/material_management/BatchPowderQualityStatusBadge';
import BatchRunSummaryContainer from 'rapidfab/containers/inventory/BatchRunSummaryContainer';
import React, { memo, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import BreadcrumbNav from 'rapidfab/components/BreadcrumbNav';
import {
  ButtonToolbar,
  Container,
  Row,
  Col,
  Card,
  Button,
} from 'react-bootstrap';
import { FormControlTextAreaWithCustomHandlers } from 'rapidfab/components/formTools';
import { getShortUUID, extractUuid } from 'rapidfab/utils/uuidUtils';
import { getRouteURI } from 'rapidfab/utils/uriUtils';
import {
  MATERIAL_BATCH_ACTION_TYPES_MAP,
  MATERIAL_BATCH_CUSTOM_STATUSES_MAP,
  MATERIAL_BATCH_STATUS_MAP,
  MATERIAL_UNITS_MAP,
} from 'rapidfab/mappings';
import { FormattedMessage } from 'rapidfab/i18n';
import Documents from 'rapidfab/components/records/Documents';
import {
  DOCUMENT_RELATED_TABLE_NAMES,
  MATERIAL_BATCH_STATUSES,
  MATERIAL_CONTAINER_STATUSES,
  ROUTES,
  NAVBAR_HEIGHT_PX,
  FEATURES,
} from 'rapidfab/constants';
import MaterialBatchMetadataColumn from 'rapidfab/components/records/material_management/MaterialBatchMetadataColumn';
import ContainerizeBlock from 'rapidfab/components/records/material_management/ContainerizeBlock';
import ContainersEditableGrid from 'rapidfab/components/records/material_management/ContainersEditableGrid';
import MaterialInfo from 'rapidfab/components/records/material_management/MaterialInfo';
import BatchEditableQuantity from 'rapidfab/components/records/material_management/BatchEditableQuantity';
import { getMaterialBatchActionQuantity } from 'rapidfab/utils/materialBatchAction';
import ResourceReadOnlyView from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyView';
import ResourceReadOnlyViewRow, { RESOURCE_READ_ONLY_VIEW_FIELD_TYPES } from 'rapidfab/components/ResourceReadOnlyView/ResourceReadOnlyViewRow';
import ManageTestPanels from 'rapidfab/components/records/material_management/ManageTestPanels';
import MaterialAddTestPanelModal from 'rapidfab/components/records/material_management/MaterialAddTestPanelModal';
import {
  materialBatchResourceType,
  printerResourceType,
  printerTypeResourceType,
  materialTypeResourceType, subLocationResourceType, userResourceType,
} from 'rapidfab/types';
import { Link } from 'react-router-dom';
import Feature from 'rapidfab/components/Feature';
import _isEmpty from 'lodash/isEmpty';
import ManageTestActions from 'rapidfab/components/records/material_management/ManageTestActions';
import SensorDataLineChart from 'rapidfab/components/SensorDataLineChart';
import { getNonEmptyMaterialContainersForBatch, isFeatureEnabled } from 'rapidfab/selectors';
import LotDetails from './material_management/LotDetails';
import FormattedNumberByLocales from '../FormattedNumberByLocales';

const TRACEABILITY_REPORT_TABS = {
  ACTION_LOG: 'action_log',
  SENSOR_HISTORY: 'sensor_history',
};

const MaterialBatch = ({
  containers,
  isLoading,
  material,
  batch,
  lot,
  printer,
  samples,
  printerType,
  printerTypeMaterials,
  printerLocation,
  locations,
  batchActions,
  scrollToTraceabilityReport,
  onSaveNotes,
  onEditBatchQuantityAction,
  isBatchActionSaving,
  isGeneralMFGLanguageEnabled,
  notes,
  onChangeNotes,
  materialTestPanels,
  materialTestPanelsByUri,
  materialAddTestPanelModalState: {
    showMaterialAddTestPanelModal,
    setShowMaterialAddTestPanelModal,
    testPanelCurrentlyEdited,
    setTestPanelCurrentlyEdited,
  },
  materialTestInstructions,
  materialTestInstructionsByUri,
  materialTestOperations,
  materialTestOperationLinkings,
  materialTestUnits,
  materialTestUnitsByUri,
  manageTestPanelsState,
  materialTestInstructionReports,
  materialTestInstructionReportsByUri,
  isMaterialTestPanelFeatureEnabled,
  onInitializeMaterialTest,
  subLocation,
  subLocations,
  usersByUri,
  materialBatchSensorDataForMaterialBatch,
  sensorDataLineChartStateCallback,
  isDebugModeEnabled,
  fetchPermanentContainers,
  permanentContainers,
  permanentContainersLoading,
  printerRelatedResourcesFetching,
}) => {
  const traceabilityReportRef = useRef();
  const [tab, setTab] = useState(TRACEABILITY_REPORT_TABS.ACTION_LOG);

  const emptyContainers = useSelector(state =>
    getNonEmptyMaterialContainersForBatch(state, batch),
  );
  const hasNonEmptyContainers = emptyContainers?.length > 0;

  const isExternalBuildIdFeatureEnabled = useSelector(state =>
    isFeatureEnabled(state, FEATURES.EXTERNAL_BUILD_ID));

  const onNotesSubmit = () => onSaveNotes(batch.uuid, notes);
  const onContainersSubmit =
    (bulkContainersOperations, reasonCode, containersEditReason) =>
      onEditBatchQuantityAction({
        uri: batch.uri,
        updatedContainers: bulkContainersOperations.update,
        notes: containersEditReason,
        reasonCode,
      });
  const onQuantityChangeSubmit =
    ({ quantity, notes: quantityEditReason, reasonCode }) =>
      onEditBatchQuantityAction({
        uri: batch.uri,
        notes: quantityEditReason,
        quantity,
        reasonCode,
      });

  useEffect(() => {
    if (scrollToTraceabilityReport) {
      window.scrollTo(
        0,
        traceabilityReportRef.current.getBoundingClientRect().top - NAVBAR_HEIGHT_PX,
      );
    }
  }, [scrollToTraceabilityReport]);

  const breadcrumbs = ['inventory', 'materialStocks', 'materialBatches'];
  if (!batch.is_initial_batch) {
    breadcrumbs.push(
      {
        message: `${lot.name} (${getShortUUID(batch.initial_batch)})`,
        href: getRouteURI(ROUTES.MATERIAL_BATCH, { uuid: extractUuid(batch.initial_batch) }),
      },
      getShortUUID(batch.uri));
  } else {
    breadcrumbs.push(`${lot.name} (${getShortUUID(batch.uri)})`);
  }

  const columns = [
    isDebugModeEnabled && ({
      debugMode: true,
      type: 'text',
      uid: 'field.id',
      accessor: 'uuid',
      defaultMessage: 'ID',
      short: true,
    }),
    {
      type: 'translatable',
      uid: 'field.type',
      accessor: 'action_type',
      defaultMessage: 'Type',
      mapping: MATERIAL_BATCH_ACTION_TYPES_MAP,
    },
    {
      type: 'custom',
      uid: 'details',
      accessor: 'metadata',
      defaultMessage: 'Details',
      Cell: ({ row: { original } }) => (
        <MaterialBatchMetadataColumn
          locations={locations}
          subLocations={subLocations}
          rowData={original}
          batchUnits={batch?.units}
        />
      ),
    },
    {
      type: 'custom',
      uid: 'field.quantity',
      accessor: 'quantity',
      defaultMessage: 'Quantity',
      Cell: ({ row: { original } }) => (
        <div className="d-flex flex-direction-row">
          <FormattedNumberByLocales number={getMaterialBatchActionQuantity(original)} shouldTruncateNumber />
          <span className="spacer-left">{MATERIAL_UNITS_MAP[batch.units].defaultMessage}</span>
        </div>
      ),
    },
    {
      type: 'time',
      uid: 'field.date',
      accessor: 'created',
      defaultMessage: 'Date',
    },
    {
      type: 'contact',
      uid: 'field.user',
      accessor: 'user',
      defaultMessage: 'User',
      users: usersByUri,
    },
  ];

  /*
    In the Select Samples Dropdown, we should show the only available samples,
    which means they should not be already linked to some Material Test Panels.
  */
  const filteredSamples = samples.filter(sample => {
    const sampleUri = sample.uri;
    const materialTestPanel = materialTestPanels.find(materialTestPanel => materialTestPanel.sample === sampleUri);
    return !materialTestPanel;
  });

  return (
    <Container fluid>
      <Feature
        featureName={FEATURES.MATERIAL_TEST_PANEL}
      >
        {showMaterialAddTestPanelModal && (
          <MaterialAddTestPanelModal
            samples={samples}
            isLoading={isLoading}
            filteredSamples={filteredSamples}
            materialTestPanel={materialTestPanelsByUri[testPanelCurrentlyEdited]}
            setTestPanelCurrentlyEdited={setTestPanelCurrentlyEdited}
            materialTestPanels={materialTestPanels}
            materialTestInstructions={materialTestInstructions}
            materialTestOperationLinkings={materialTestOperationLinkings}
            materialTestOperations={materialTestOperations}
            materialTestUnits={materialTestUnits}
            materialTestUnitsByUri={materialTestUnitsByUri}
            onClose={() => setShowMaterialAddTestPanelModal(false)}
            onInitializeMaterialTest={onInitializeMaterialTest}
          />
        )}
      </Feature>
      <BreadcrumbNav breadcrumbs={breadcrumbs} />

      <ButtonToolbar className="clearfix">
        <div className="pull-left btn-toolbar-item">
          <div>
            <Link
              to={getRouteURI(ROUTES.MATERIAL_BATCH_GENEALOGY, { uuid: extractUuid(batch.uri) }, {}, true)}
            >
              Genealogy View
            </Link>
          </div>
        </div>
      </ButtonToolbar>

      <hr />

      <Row>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Card bg="dark" className="mb15 custom-darken-modal--card">
            <Card.Header className="pd-exp accent custom-darken-modal--card-header-accent">
              <span>Batch Details</span>
              {
                hasNonEmptyContainers && (
                  <a
                    href={getRouteURI(ROUTES.PRINT_BATCH_CONTAINERS_QR_CODES, { uuid: batch.uuid })}
                    className="pull-right text-white"
                  >
                    <BureauBarcodeIcon
                      className="spacer-right"
                      qrCodeText="Print All"
                      barcodeText="Print All"
                    />
                  </a>
                )
              }
            </Card.Header>
            <div className="card-body-wrapper-accent">
              <Card.Body className="custom-darken-modal--card-body">
                <Row>
                  <Col xs={{ span: 6 }}>
                    <ResourceReadOnlyView
                      entity={batch}
                    >
                      <ResourceReadOnlyViewRow
                        name="location_name"
                        label="Location"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={batch && `${batch.location_name} ${subLocation ? `(${subLocation.name})` : ''}`}
                      />
                      {!!batch.quantity && (
                        <ResourceReadOnlyViewRow
                          name="material_in_containers"
                          label="Track Individual Containers"
                          type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.BOOLEAN}
                        />
                      )}
                      <ResourceReadOnlyViewRow name="materials" type="custom" customValue={batch.materials.map(material => material.name).join(', ')} label={batch.materials.length > 1 ? 'Materials' : 'Material'} className="wrap-text" />
                      <ResourceReadOnlyViewRow
                        name="quantity"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={batch && `${batch.quantity} (${MATERIAL_UNITS_MAP[batch.units].defaultMessage})`}
                      />
                      <ResourceReadOnlyViewRow
                        name="status"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={
                          batch
                      && MATERIAL_BATCH_STATUS_MAP[batch.status]
                      && (
                        <>
                          <FormattedMessage
                            id={MATERIAL_BATCH_STATUS_MAP[batch.status].id}
                            defaultMessage={MATERIAL_BATCH_STATUS_MAP[batch.status].defaultMessage}
                          />
                          <BatchPowderQualityStatusBadge
                            qualityStatus={batch.powder_quality}
                          />
                        </>
                      )
                        }
                      />
                      <ResourceReadOnlyViewRow
                        name="custom_status"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                        customValue={(
                          batch
                      && MATERIAL_BATCH_CUSTOM_STATUSES_MAP[batch.custom_status]
                      && (
                        <FormattedMessage
                          id={MATERIAL_BATCH_CUSTOM_STATUSES_MAP[batch.custom_status].id}
                          defaultMessage={MATERIAL_BATCH_CUSTOM_STATUSES_MAP[batch.custom_status].defaultMessage}
                        />
                      )
                        )}
                      />
                      <ResourceReadOnlyViewRow name="usage_cycles" />
                      {isExternalBuildIdFeatureEnabled && (
                        <ResourceReadOnlyViewRow name="external_build_id" label="External Build ID" />
                      )}
                      <ResourceReadOnlyViewRow
                        name="updated"
                        type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.DATETIME}
                      />
                    </ResourceReadOnlyView>
                  </Col>
                  <Col xs={{ span: 6 }}>
                    <ContainerizeBlock
                      batch={batch}
                      fetchedPermanentContainers={permanentContainers}
                      fetchPermanentContainers={fetchPermanentContainers}
                      permanentContainersLoading={permanentContainersLoading}
                    />
                  </Col>
                  <Col xs={{ span: 12 }} className="mt15">
                    <b>Notes:</b>
                    <FormControlTextAreaWithCustomHandlers
                      name="notes"
                      value={notes}
                      onChange={onChangeNotes}
                    />
                    <Button
                      onClick={onNotesSubmit}
                      size="sm"
                      variant="success"
                      className="pull-right mt15"
                    >
                      Save
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </div>
          </Card>

          {batch.status === MATERIAL_BATCH_STATUSES.DONE && (
            <Feature featureName={FEATURES.EXTERNAL_BUILD_ID} isInverted>
              <BatchRunSummaryContainer batchUri={batch.uri} />
            </Feature>
          )}
          {printerRelatedResourcesFetching ? <Loading /> : (
            <ResourceReadOnlyView
              isDarken
              withPanelWrapper
              title={isGeneralMFGLanguageEnabled ? 'Production Device' : 'Printer'}
              entity={printer}
              placeholder="This batch is not currently loaded in a machine"
            >
              <ResourceReadOnlyViewRow
                label="Printer Name"
                type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                customValue={
                  printer
                    && (
                      <>
                        <Link
                          to={getRouteURI(ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer.uri) }, {}, true)}
                        >
                          {' '}{printer.name}
                        </Link>
                        <Link
                          to={getRouteURI(ROUTES.PRINT_PRINTER_QR, { uuid: extractUuid(printer.uri) }, {}, true)}
                        >
                          <BureauBarcodeIcon
                            className="spacer-left"
                          />
                        </Link>
                      </>
                    )
                }
              />
              <ResourceReadOnlyViewRow
                label="Printer Type"
                type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                customValue={printerType && printerType.name}
              />
              <ResourceReadOnlyViewRow
                label="Location"
                type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                customValue={printerLocation && printerLocation.name}
              />
              <ResourceReadOnlyViewRow
                label="Supported Material Types"
                className="wrap-text"
                type={RESOURCE_READ_ONLY_VIEW_FIELD_TYPES.CUSTOM}
                customValue={printerTypeMaterials && printerTypeMaterials.map(({ name }) => name).join(', ')}
              />
            </ResourceReadOnlyView>
          )}

          {lot && (
            <LotDetails
              lot={lot}
              material={material}
              batch={batch}
              isDarken
            />
          )}

          <MaterialInfo material={material} />
        </Col>
        <Col xs={{ span: 12 }} md={{ span: 6 }}>
          <Documents
            relatedTable={DOCUMENT_RELATED_TABLE_NAMES.MATERIAL_BATCH}
            relatedUUID={extractUuid(batch.uri)}
            isCustomDarkCardStyleAccent
            isCustomDarkCardStyle
          />
          <div ref={traceabilityReportRef}>
            <MaterialBatchTraceabilityReport
              batch={batch}
              batchActions={batchActions}
              columns={columns}
              tab={tab}
              setTab={setTab}
              isDarken
            />
          </div>
          {
            isMaterialTestPanelFeatureEnabled && (
              <Card bg="dark" className="mb15 custom-darken-modal--card">
                <Card.Header className="pd-exp custom-darken-modal--card-header">
                  Test Results
                </Card.Header>
                <div>
                  {/* We should also hide the panel if we have no Samples from API at all. */}

                  {(!_isEmpty(materialTestOperationLinkings) && !_isEmpty(samples)) ?
                    (
                      <ManageTestPanels
                        filteredSamples={filteredSamples}
                        testPanels={materialTestPanels}
                        materialTestInstructions={materialTestInstructions}
                        materialTestInstructionsByUri={materialTestInstructionsByUri}
                        materialTestOperations={materialTestOperations}
                        materialTestUnitsByUri={materialTestUnitsByUri}
                        showMaterialAddTestPanelModal={() => setShowMaterialAddTestPanelModal(true)}
                        setTestPanelCurrentlyEdited={setTestPanelCurrentlyEdited}
                        manageTestPanelsState={manageTestPanelsState}
                        materialTestInstructionReports={materialTestInstructionReports}
                        materialTestInstructionReportsByUri={materialTestInstructionReportsByUri}
                        materialTestOperationLinkings={materialTestOperationLinkings}
                        isDarken
                      />
                    ) : (
                      <ManageTestActions batch={batch} />
                    )}
                </div>

              </Card>
            )
          }
          {materialBatchSensorDataForMaterialBatch && (
            <div id="sensorDataContainer">
              <Feature
                featureNamesEnforceAll
                featureNames={[
                  FEATURES.SENSORS,
                  FEATURES.MATERIAL_MANAGEMENT,
                ]}
              >
                <SensorDataLineChart
                  data={materialBatchSensorDataForMaterialBatch}
                  selectedSubLocation={null}
                  setStateCallback={sensorDataLineChartStateCallback}
                />
              </Feature>
            </div>
          )}
        </Col>
      </Row>

      {hasNonEmptyContainers && (
        <ContainersEditableGrid
          panelHeading="Material Storage"
          deleteContainersAllowed={false}
          addContainersAllowed={false}
          showAddedToBatchColumn={false}
          showReasonInput
          containers={containers}
          units={batch.units}
          onSaveContainers={onContainersSubmit}
          isSaving={isBatchActionSaving}
          isContainerEditable={
            container => container.status !== MATERIAL_CONTAINER_STATUSES.EMPTY
          }
          uuid={batch.uuid}
          route={ROUTES.PRINT_BATCH_CONTAINER_QR_CODE}
          isDebugModeEnabled={isDebugModeEnabled}
        />
      )}
      {
        !hasNonEmptyContainers
        && batch.status !== MATERIAL_BATCH_STATUSES.DONE
        && !batch.at_machine
        && (
          <BatchEditableQuantity
            batch={batch}
            onSaveQuantityChange={onQuantityChangeSubmit}
          />
        )
      }
    </Container>
  );
};

MaterialBatch.propTypes = {
  containers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  batch: materialBatchResourceType.isRequired,
  lot: PropTypes.shape({
    name: PropTypes.string,
    delivery_date: PropTypes.string,
    delivery_id: PropTypes.string,
    expiration_date: PropTypes.string,
    usage_cycles_limit: PropTypes.number,
    initial_batches: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  printer: printerResourceType,
  printerType: printerTypeResourceType,
  printerTypeMaterials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
  })),
  printerLocation: PropTypes.shape({
    name: PropTypes.string,
  }),
  samples: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  material: materialTypeResourceType.isRequired,
  batchActions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  scrollToTraceabilityReport: PropTypes.bool,
  onSaveNotes: PropTypes.func.isRequired,
  onEditBatchQuantityAction: PropTypes.func.isRequired,
  isBatchActionSaving: PropTypes.bool.isRequired,
  isGeneralMFGLanguageEnabled: PropTypes.bool.isRequired,
  row: PropTypes.shape({
    original: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
  }).isRequired,
  notes: PropTypes.string,
  onChangeNotes: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  materialTestPanels: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestPanelsByUri: PropTypes.shape({}).isRequired,
  materialAddTestPanelModalState: PropTypes.shape({
    showMaterialAddTestPanelModal: PropTypes.bool,
    setShowMaterialAddTestPanelModal: PropTypes.func,
    testPanelCurrentlyEdited: PropTypes.bool,
    setTestPanelCurrentlyEdited: PropTypes.func,
  }).isRequired,
  materialTestInstructions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestInstructionsByUri: PropTypes.shape({}).isRequired,
  materialTestOperations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestUnitsByUri: PropTypes.shape({}).isRequired,
  manageTestPanelsState: PropTypes.shape({}).isRequired,
  materialTestInstructionReports: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestOperationLinkings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  materialTestInstructionReportsByUri: PropTypes.shape({}).isRequired,
  isMaterialTestPanelFeatureEnabled: PropTypes.bool,
  materialTestUnits: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onInitializeMaterialTest: PropTypes.func.isRequired,
  subLocation: subLocationResourceType.isRequired,
  usersByUri: PropTypes.objectOf(userResourceType).isRequired,
  materialBatchSensorDataForMaterialBatch: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  sensorDataLineChartStateCallback: PropTypes.func.isRequired,
  subLocations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isDebugModeEnabled: PropTypes.bool.isRequired,
  fetchPermanentContainers: PropTypes.func.isRequired,
  permanentContainers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  permanentContainersLoading: PropTypes.bool.isRequired,
  printerRelatedResourcesFetching: PropTypes.bool.isRequired,
};

MaterialBatch.defaultProps = {
  scrollToTraceabilityReport: false,
  printer: null,
  printerType: {},
  printerTypeMaterials: [],
  printerLocation: {},
  notes: '',
  isMaterialTestPanelFeatureEnabled: false,
};

export default memo(MaterialBatch);
