import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Actions from 'rapidfab/actions';
import * as Selectors from 'rapidfab/selectors';
import ModelerModal from 'rapidfab/components/ModelerModal';
import { API_RESOURCES, PAGINATION_IGNORE_DEFAULT_LIMIT } from 'rapidfab/constants';
import _get from 'lodash/get';
import _set from 'lodash/set';

import { connect } from 'react-redux';
import { MODELER_MODAL_CONTAINER } from 'rapidfab/constants/forms';

const ModelerModalContainer = ({ onInitialize, uuid, ...otherProps }) => {
  useEffect(() => {
    onInitialize(uuid);
  }, []);

  return (
    <ModelerModal uuid={uuid} {...otherProps} />
  );
};

ModelerModalContainer.defaultProps = {
  uuid: null,
};

ModelerModalContainer.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  uuid: PropTypes.string,
  onInitialize: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
};

function mapStateToProps(state, props) {
  const isLoading = state.ui.nautilus[API_RESOURCES.MODELER].get.fetching;

  const isSubmitting = state.ui.nautilus[API_RESOURCES.MODELER].put.fetching ||
    state.ui.nautilus[API_RESOURCES.MODELER].post.fetching;

  const modeler = props.uuid
    && Selectors.getUUIDResource(state, props.uuid);

  const modelerTypes = Selectors.getModelerTypes(state);

  // Ignoring password field since it is converted to hash on each submit
  const { password, ...initialValues } = modeler || {
    // Setting first modeler type as selected by default
    type: modelerTypes[0] && modelerTypes[0].uri,
  };

  const initialFormValues = {};
  Object
    .keys(initialValues)
    .filter(key => MODELER_MODAL_CONTAINER.FIELDS.includes(key))
    .forEach(key => {
      initialFormValues[key] = initialValues[key];
    });

  return {
    isLoading,
    isSubmitting,
    modeler,
    modelerTypes,
    initialFormValues,
  };
}

function mapDispatchToProps(dispatch, ownProps) {
  return {
    onInitialize: uuid => {
      if (uuid) {
        dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(uuid));
      }
      dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER_TYPE].list(
        {},
        { limit: PAGINATION_IGNORE_DEFAULT_LIMIT }),
      );
    },
    onFormSubmitNew: async payload => {
      let { uri } = payload;

      MODELER_MODAL_CONTAINER.STRING_FIELDS.forEach(
        fieldName => {
          const field = _get(payload, fieldName);
          if (!field) {
            _set(payload, fieldName, '');
          }
        },
      );

      if (payload.uuid) {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].put(payload.uuid, payload));
      } else {
        await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER]
          .post(payload)).then(result => {
          uri = result.headers.location;
        });
      }
      ownProps.onSave(uri);
    },
    onFormSubmitEdit: async payload => {
      await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].put(ownProps.uuid, payload));
      const { uri } = await dispatch(Actions.Api.nautilus[API_RESOURCES.MODELER].get(ownProps.uuid));
      ownProps.onClose(uri);
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelerModalContainer);
