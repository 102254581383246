import {
  faClock, faCube,
  faInfoCircle, faPrint,
  faShareFromSquare, faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import Loading from 'rapidfab/components/Loading';
import ResourceIconTooltip from 'rapidfab/components/ResourceIconTooltip';
import TruncatedTitle from 'rapidfab/components/TruncatedTitle';
import { ROUTES } from 'rapidfab/constants';
import { resetRcTooltipInnerStyle } from 'rapidfab/constants/styles';
import PrinterTypeIcon from 'rapidfab/icons/printer-type-icon';
import { extractUuid, getShortUUID } from 'rapidfab/utils/uuidUtils';
import RCTooltip from 'rc-tooltip';
import React from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';

const BatchRunSummary = ({
  data,
  fetchingStates,
  actions,
}) => {
  const {
    run,
    printer,
    printerType,
    duration,
    pieceCount,
    lineItemsCount,
    startTime,
    endTime,
    lineItemsForRun,
    lineItemsInitialLimit,
  } = data ?? {};

  const {
    runFetching,
    printerFetching,
    printerTypeFetching,
    piecesFetching,
    lineItemsResourcesFetching,
    runActualsFetching,
  } = fetchingStates ?? {};

  const {
    toggleLineItemsModal,
  } = actions ?? {};

  if (!run) return null;
  if (runFetching) return <Loading className="mb10" />;

  const renderResourceCount = (resourceIcon, resourceText, resourceCount, isFetching) => {
    let content;

    if (isFetching) {
      content = <Loading inline />;
    } else if ((resourceCount || resourceCount === 0)) {
      content = resourceCount;
    } else {
      content = 'N/A';
    }

    return (
      <>
        <ResourceIconTooltip
          faIcon={resourceIcon}
          iconClassNames="spacer-right"
          text={resourceText}
        />
        {content}
      </>
    );
  };

  const renderWorkstationResource = (name, routeArguments, isFetching) => {
    if (isFetching) return <Loading inline />;
    if (!name && !isFetching) return 'N/A';

    return (
      <TruncatedTitle
        title={name}
        maxTextLength={30}
        titleClassName="font-weight-400"
        titleLinkToProps={routeArguments}
        inline
      />
    );
  };

  const renderRunActualsTime = (title, tooltipText, formattedDate) => {
    let content;

    if (runActualsFetching) {
      content = <Loading inline className="d-flex justify-content-start" />;
    } else if (formattedDate) {
      content = (
        <div>{formattedDate}</div>
      );
    } else {
      content = 'N/A';
    }

    return (
      <div className="d-flex align-items-center">
        <ResourceIconTooltip
          faIcon={faClock}
          iconClassNames="spacer-right mt15"
          text={tooltipText}
        />
        <div className="d-flex flex-column">
          <div className="font-size-12 font-weight-200">{title}</div>
          {content}
        </div>
      </div>
    );
  };

  const renderRunActuals = () => (
    <div className="ResourceCardBottomViewCard mb15">
      <div>
        {renderRunActualsTime('Start', 'Run Actuals Start Time', startTime)}
      </div>
      <div className="mt-2 d-flex align-items-center">
        {renderRunActualsTime('End', 'Run Actuals End Time', endTime)}
      </div>
      <div className="mt-2 d-flex align-items-center">
        {renderRunActualsTime('Duration', 'Run Actuals Duration', duration)}
      </div>
    </div>
  );

  const renderWorkstationData = () => (
    <div className="ResourceCardBottomViewCard mb15">
      <div>
        <ResourceIconTooltip
          faIcon={faPrint}
          iconClassNames="spacer-right"
          text="Printer"
        />
        {renderWorkstationResource(
          printer?.name,
          [ROUTES.PRINTER_EDIT, { uuid: extractUuid(printer?.uri) }, {}, true],
          printerFetching,
        )}
      </div>
      <div className="mt-2 d-flex align-items-center">
        <ResourceIconTooltip
          customIcon={PrinterTypeIcon}
          customIconClassNames="printer-type-resource-icon"
          iconClassNames="spacer-right"
          text="Printer Type"
        />
        {renderWorkstationResource(
          printerType?.name,
          [
            ROUTES.PRINTER_TYPES,
            {},
            { uuid: extractUuid(printerType?.uri) },
            true,
          ],
          printerTypeFetching,
        )}
      </div>
    </div>
  );

  const renderResourcesDetails = () => (
    <div className="ResourceCardBottomViewCard d-flex align-items-center">
      <div>
        {renderResourceCount(faWrench, 'Pieces', pieceCount, piecesFetching)}
      </div>
      <div className="ml10">
        {renderResourceCount(faCube, 'Line Items', lineItemsCount, lineItemsResourcesFetching)}
        {!!lineItemsCount
          && !lineItemsResourcesFetching
          && !_isEmpty(lineItemsForRun)
          && (
            <RCTooltip
              overlayInnerStyle={resetRcTooltipInnerStyle}
              placement="top"
              destroyTooltipOnHide
              mouseLeaveDelay={0.4}
              overlay={(
                <div className="p-10">
                  {lineItemsForRun.slice(0, 10).map(({ order, name }) => (
                    <div key={name} className="font-size-14">
                      <TruncatedTitle
                        title={name}
                        icon={faCube}
                        titleClassName="font-weight-200"
                        iconClassName="spacer-right text-white"
                        maxTextLength={30}
                        titleLinkToProps={[
                          ROUTES.ORDER_EDIT,
                          { uuid: extractUuid(order) },
                          {},
                          true,
                        ]}
                        inline
                      />
                    </div>
                  ))}
                  {lineItemsCount > lineItemsInitialLimit && (
                    <Button variant="link" className="users-list-locations-button mt15 ml0" onClick={toggleLineItemsModal}>
                      Click here to see all
                    </Button>
                  )}
                </div>
              )}
            >
              <FontAwesomeIcon icon={faInfoCircle} className="custom-darken-modal--tooltip-icon spacer-left" />
            </RCTooltip>
          )}
      </div>
    </div>
  );

  return (
    <Card bg="dark" className="mb15 custom-darken-modal--card">
      <Card.Header className="custom-darken-modal--card-header">Build Summary</Card.Header>
      <div>
        <Card.Body className="custom-darken-modal--card-body">
          <div className="card-list ResourceCardList">
            <div
              className="card ResourceCard"
            >
              <Row className="ResourceCardTopView">
                <Col xs={5}>
                  <div className="ResourceCardResources">
                    <div className="spacer-right">
                      <ResourceIconTooltip
                        faIcon={faShareFromSquare}
                        text="Run"
                      />
                    </div>
                    <div>
                      <TruncatedTitle
                        title={run.name}
                        maxTextLength={30}
                        titleClassName="font-weight-400"
                        titleLinkToProps={[
                          ROUTES.RUN_EDIT,
                          { uuid: extractUuid(run.uri) },
                          {},
                          true,
                        ]}
                        inline
                      />
                      <div className="ResourceCardPrinterType">
                        {getShortUUID(run.uri)}
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="ResourceCardBottomView">
                <Col xs={5}>
                  <div className="mb30">
                    <div className="font-weight-200 mb5 font-size-14">Workstation</div>
                    {renderWorkstationData()}
                  </div>
                  {renderResourcesDetails()}
                </Col>
                <Col xs={5}>
                  <div className="font-weight-200 mb5 font-size-14">Run Actuals</div>
                  {renderRunActuals()}
                </Col>
              </Row>
            </div>
          </div>
        </Card.Body>
      </div>
    </Card>
  );
};

BatchRunSummary.propTypes = {
  data: PropTypes.shape({
    run: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    printer: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    printerType: PropTypes.shape({
      name: PropTypes.string,
      uri: PropTypes.string,
    }),
    duration: PropTypes.string,
    pieceCount: PropTypes.number,
    lineItemsCount: PropTypes.number,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    lineItemsForRun: PropTypes.arrayOf(PropTypes.shape({
      order: PropTypes.string,
      name: PropTypes.string,
    })),
    lineItemsInitialLimit: PropTypes.number,
  }).isRequired,
  fetchingStates: PropTypes.shape({
    runFetching: PropTypes.bool,
    printerFetching: PropTypes.bool,
    printerTypeFetching: PropTypes.bool,
    piecesFetching: PropTypes.bool,
    lineItemsResourcesFetching: PropTypes.bool,
    runActualsFetching: PropTypes.bool,
  }).isRequired,
  actions: PropTypes.shape({
    toggleLineItemsModal: PropTypes.func,
  }).isRequired,
};

export default BatchRunSummary;
